export default {
    style1: { flex: 1, height: 0 },
    flexOne: { flex: 1 },
    style2: {
        flex: 1.75,
        overflowY: "auto",
        overflowX: "hidden",
    },
    fullHeightCentered: {
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    tokens1: { childrenGap: 8, padding: 16 },
    tokens2: { childrenGap: 8, padding: 16 },
};
