import {
	ActionButton,
	BaseButton,
	DefaultButton,
	IconButton,
	PrimaryButton,
	Stack,
	TextField,
} from "office-ui-fabric-react";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../components/Logo";
import { useUserActions, useUserState } from "../../UserContext";
import "./index.css";

export default function SignUp() {
	const { replace, location } = useHistory();
	const { loading, authorization } = useUserState();
	const { sign_up } = useUserActions();

	const name_ref = useRef();
	const user_name_ref = useRef();
	const password_ref = useRef();
	const password_check_ref = useRef();

	function submit() {
		const name = name_ref.current.value;
		const user_name = user_name_ref.current.value;
		const password = password_ref.current.value;
		const password_check = password_check_ref.current.value;

		sign_up(name, user_name, password, password_check);
	}
	function handleKeyDown(e) {
		if (e.key === "Enter") {
			submit();
		}
	}

	useEffect(() => {
		if (!authorization) return;
		if (location.pathname !== "/sign_up") return;

		replace("/");
	}, [authorization, location]);

	function sign_in() {
		replace("/sign_in");
	}

	return (
		<div className={"sign_in-form"} onKeyDown={handleKeyDown}>
			<Logo />
			<Stack>
				<TextField
					required
					disabled={loading}
					componentRef={name_ref}
					label="Nome"
					type="name"
				/>
				<TextField
					required
					disabled={loading}
					componentRef={user_name_ref}
					label="Usuário"
					type="username"
				/>
				<TextField
					required
					disabled={loading}
					componentRef={password_ref}
					label="Senha"
					type="password"
				/>
				<TextField
					required
					disabled={loading}
					componentRef={password_check_ref}
					label="Confirmar Senha"
					type="password"
				/>
				<PrimaryButton
					disabled={loading}
					style={{ marginTop: 8 }}
					onClick={submit}
					text="Registrar"
				/>
				<ActionButton
					iconProps={{
						iconName: "Back",
					}}
					text={"Já tenho uma conta"}
					onClick={sign_in}
				/>
			</Stack>
		</div>
	);
}
