const orders_columns = ["ticker", "book_1", "book_2"];
const overview_columns = [
	"ticker",
	"currency",
	"price_d0",
	"dtd_pct",
	"delta_notional_pct",
	"equity_exposure_pct",
	"position_pnl_pct",
	"trades_pnl_pct",
	"pnl_pct",
	"pnl",
];

const resume_columns = [
	"rps_name",
	"pnl",
	"pnl_pct",
	"equity_exposure_pct",
	"position_pnl_pct",
	"trades_pnl_pct",
	"dv01_pct",
];

const indicators_columns = [
	"description",
	"price_d0",
	"dtd_pct",
	"mtd_pct",
	"ytd_pct",
];

const available_columns = [
	"rps_setor",
	"rps_subsetor",
	"ticker",
	"currency",
	"weight",
	"weight_d1",
	"price_d1",
	"price_d0",
	"dtd_pct",
	"mtd_pct",
	"ytd_pct",
	"position_pnl_pct",
	"trades_pnl_pct",
	"pnl_pct",
	"position_pnl_alpha_ibx_pct",
	"ow_uw_pct",
	"amount_d1",
	"amount",
	"position_d1",
	"position",
	"position_pct",
	"fx_exposure_usdbrl_pct",
	"trades_equity_exposure",
	"trades_equity_exposure_pct",
	"equity_exposure",
	"equity_exposure_pct",
	"delta_notional_pct",
	"gross_pct_trading_desk",
	"gross_pct_book_1",
	"gross_pct_book_2",
	"pnl",
];

export const columns = {
	orders: orders_columns,
	overview: overview_columns,
	resume: resume_columns,
	indicators: indicators_columns,
	available: available_columns,
};
