import React, { Fragment } from "react";
import {
    Stack,
    StackItem,
    Label,
    Text,
    Separator,
    Shimmer,
} from "office-ui-fabric-react";
import { isMobile } from "react-device-detect";

const settings = {
    horizontal: true,
    tokens: {
        childrenGap: 8,
    },
};
const textStyles = (color, weight) => ({
    root: {
        fontSize: isMobile ? undefined : 20,
        color: color || "unset",
        fontWeight: weight || "unset",
    },
});

const GeneralDisplay = ({ info, template, children, loading, separation }) => {
    template = template || {};
    const checkProp = (key, prop = null, target = null) => {
        const item = target ? target[key] : template[key];
        if (!prop) return item;
        if (!item) return false;
        return !!item[prop];
    };
    const getName = (key) =>
        (checkProp(key, "name") && template[key].name) || key;
    const getWeight = (key) => checkProp(key, "weight") && template[key].weight;

    const getColor = (key, val) =>
        (val && checkProp(key, "color") && template[key].color(val)) || "unset";

    const getValue = (key, val) =>
        (val && checkProp(key, "value") && template[key].value(val)) || val;

    const entries = Object.entries(info);
    return (
        <>
            {entries
                .filter(([k, v]) => v !== undefined)
                .map(([k, v], i) => (
                    <Fragment key={k}>
                        {i
                            ? getName(k).length !== 1 && <Separator vertical />
                            : null}
                        <StackItem>
                            <Label>{getName(k)}</Label>
                            <Shimmer
                                styles={{
                                    shimmerWrapper: {
                                        marginTop: 5,
                                        minWidth: 64,
                                        height: 20,
                                    },
                                }}
                                isDataLoaded={!loading}
                            >
                                {!loading && (
                                    <Text
                                        styles={textStyles(
                                            getColor(k, v),
                                            getWeight(k)
                                        )}
                                    >
                                        {(v !== null &&
                                            String(getValue(k, v))) ||
                                            v}
                                    </Text>
                                )}
                            </Shimmer>
                        </StackItem>
                    </Fragment>
                ))}
        </>
    );
};
export default GeneralDisplay;
