import styled from "styled-components";

export const Container = styled.div`
	overflow: auto;
	background-color: #282c34;
	height: 100%;
	.clickable {
		cursor: pointer;
		margin-top: 4px;
	}
	tr {
		position: relative;
	}
	table {
		width: ${({ width }) => width || "100%"};
		border-spacing: 0;
		user-select: none;

		thead {
			td {
				background-color: white;
				position: sticky;
				top: 0;
				z-index: 1;
				.text {
					font-weight: 600;
				}

				.ms-sortIcon {
					margin-right: 4px;
					font-size: 12px;
					color: grey;
				}
				.ms-sortIcon:hover {
					cursor: pointer;
				}
			}
			.fill {
				background-color: white;
				width: 100%;
			}
		}
		tbody {
			td {
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			}
			.child1 {
				td {
					:first-child {
						.container {
							padding-left: 16px;
						}
					}
					background-color: rgba(0, 0, 0, 0.1);
				}
			}
			.child2 {
				td {
					:first-child {
						.container {
							padding-left: 24px;
						}
					}
					background-color: rgba(0, 0, 0, 0.15);
				}
			}
			.child3 {
				td {
					:first-child {
						.container {
							padding-left: 32px;
						}
					}
					background-color: rgba(0, 0, 0, 0.2);
				}
			}
			.child4 {
				td {
					:first-child {
						.container {
							padding-left: 40px;
						}
					}
					background-color: rgba(0, 0, 0, 0.25);
				}
			}
			.child5 {
				td {
					:first-child {
						.container {
							padding-left: 48px;
						}
					}
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
			.child6 {
				td {
					:first-child {
						.container {
							padding-left: 56px;
						}
					}
					background-color: rgba(0, 0, 0, 0.35);
				}
			}
			.child7 {
				td {
					:first-child {
						.container {
							padding-left: 64px;
						}
					}
					background-color: rgba(0, 0, 0, 0.4);
				}
			}

			tr:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}

			.child {
				background-color: #24272e;
			}
		}
	}
`;
