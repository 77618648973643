import calcs from "./calcs";
import { standardDeviation } from "./ArrayMath";

export const mainReport = (data, patrimony) => {
	const chartData = {};
	for (const { prices } of data) {
		for (const { date, profit } of prices) {
			if (chartData[date] === undefined) {
				chartData[date] = 0;
			}
			if (profit !== undefined) {
				chartData[date] += profit;
			}
		}
	}

	const categories = Object.keys(chartData);
	const profitSeries = [];
	for (let date of categories) {
		profitSeries.push(chartData[date] / patrimony);
	}
	return { profitSeries };
};
export const shortLongReport = (data) => {
	const final = { short: {}, long: {} };
	for (const { profit_percent, exposition } of data) {
		const target = exposition > 0 ? "long" : "short";
		for (const i in profit_percent) {
			if (final[target][i] === undefined) {
				final[target][i] = profit_percent[i];
			} else {
				final[target][i] += profit_percent[i];
			}
		}
	}
	const { short, long } = final;

	const profitSeriesShort = [];
	const profitSeriesLong = [];

	for (const key of Object.keys(short)) {
		profitSeriesShort.push(short[key]);
		profitSeriesLong.push(long[key]);
	}
	return { profitSeriesShort, profitSeriesLong };
};

export const windowCumulativeReport = (series, window = 21) => {
	if (series.length <= window) return null;
	const acumulated = [];
	const final = series.reduce((t, v) => {
		t = t * (1 + v);
		acumulated.push(t);
		return t;
	}, 1);

	const newSeries = [];
	for (let i = 0; i < window - 1; i++) {
		newSeries.push(null);
	}
	let start = 0;
	while (window <= series.length) {
		const value = acumulated[window - 1] / acumulated[start] - 1;
		newSeries.push(value);
		window++;
		start++;
	}
	return newSeries;
};

export const trackingError = (profit_percent_series, index_series) => {
	if (!index_series.length) return 0;
	const alphas = [];

	for (let i in index_series) {
		i = parseInt(i);
		if (i === 0) continue;
		alphas.push(profit_percent_series[i] - index_series[i]);
	}

	return standardDeviation(alphas) * Math.sqrt(252);
};

export const assetPerformance = (prices, interval = 22) => {
	if (!prices.length) return 0;
	if (prices.length >= interval) {
		const price0 = prices[prices.length - 1];
		let priceI = prices[0];
		if (interval > 0) priceI = prices[prices.length - interval];
		return price0 / priceI - 1;
	}
};
export const assetsPerformance = (data, interval = 22) => {
	const final = [];
	for (const item of data) {
		const { symbol, exposition, exposition_percent, prices } = item;
		let performance = assetPerformance(prices, interval) || 0;

		final.push({
			symbol,
			exposition,
			exposition_percent,
			performance,
		});
	}

	return final;
};

export const ranking = (data, key, top = 10) => {
	const best = [];
	const worst = [];

	for (const item of data) {
		const value = item.performance;

		if (value > 0) best.push(item);
		if (value < 0) worst.push(item);
	}

	return {
		best: best.sort((a, b) => b[key] - a[key]).slice(0, top),
		worst: worst.sort((a, b) => a[key] - b[key]).slice(0, top),
	};
};

export const top10change = (data, top = 10) => {
	let positiveCandidates = [];
	let negativeCandidates = [];
	for (let { symbol, type, performance } of data) {
		if (type === "option") continue;

		if (performance > 0) {
			positiveCandidates.push({ symbol, performance });
		} else if (performance < 0) {
			negativeCandidates.push({
				symbol,
				performance,
			});
		}
	}
	positiveCandidates = positiveCandidates
		.sort((a, b) => b.performance - a.performance)
		.slice(0, top);
	negativeCandidates = negativeCandidates
		.sort((a, b) => a.performance - b.performance)
		.slice(0, top);

	const longSeries = [];
	const longCategories = [];

	for (let { symbol, performance } of positiveCandidates) {
		longSeries.push(performance);
		longCategories.push(symbol);
	}

	const shortSeries = [];
	const shortCategories = [];

	for (let { symbol, performance } of negativeCandidates) {
		shortSeries.push(performance);
		shortCategories.push(symbol);
	}

	return {
		long: {
			series: longSeries,
			categories: longCategories,
		},
		short: {
			series: shortSeries,
			categories: shortCategories,
		},
	};
};
export const dataToSheet = (data, head = [], keys = [], options = {}) => {
	//const className = (i) => (i > 0 ? "sheet-cell-center" : "sheet-cell-left");
	head = head.map((value, i) => ({
		value,
		align: `sheet-cell-${options.align || (i ? "right" : "left")}`,
	}));
	const sheet = [];

	for (const item of data) {
		const row = [];
		for (const i in keys) {
			const key = keys[i];
			row.push({
				key,
				value: item[key],
				align: options.align
					? options.align
					: options[key]
					? options[key].align
					: undefined,
			});
		}
		sheet.push(row);
	}
	sheet.unshift(head);

	return sheet;
};

export const indexPerformance = (index, index_spx, profit_series, dates) => {
	let positive = [];
	let negative = [];

	for (const i in index.change) {
		const profit = profit_series[i];
		const change = index.change[i];
		const change_spx = index_spx.change[i];
		const date = dates[i];

		if (profit > 0) {
			positive.push({ date, change, change_spx, profit });
		} else {
			negative.push({ date, change, change_spx, profit });
		}
	}

	const head = ["DATA", "BOVA", "SPX", "COTA"];
	const keys = ["date", "change", "change_spx", "profit"];
	positive = positive.sort((a, b) => b.profit - a.profit).slice(0, 25);

	const _mean = (final, item, i, o) => {
		final.change += item.change;
		final.change_spx += item.change_spx;
		final.profit += item.profit;

		if (i === o.length - 1) {
			final.change /= o.length;
			final.change_spx /= o.length;
			final.profit /= o.length;
		}
		return final;
	};
	const initialValue = { date: "MÉDIA", change: 0, change_spx: 0, profit: 0 };

	positive.push(positive.reduce(_mean, initialValue));

	positive = dataToSheet(positive, head, keys);

	negative = negative.sort((a, b) => a.profit - b.profit).slice(0, 25);
	negative.push(negative.reduce(_mean, initialValue));

	negative = dataToSheet(negative, head, keys);

	return { positive, negative };
};
