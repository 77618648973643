import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { initializeIcons } from "@uifabric/icons";
import "./index.css";
import UserContext from "./UserContext";

initializeIcons();

function Container() {
	return (
		<UserContext>
			<App />
		</UserContext>
	);
}

ReactDOM.render(<Container />, document.getElementById("root"));
