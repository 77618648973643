import React from "react";
import { DatePicker, Label, Stack } from "office-ui-fabric-react";
import DayPickerStrings from "../../services/DayPicketStrings";

function formatDate(date) {
	return date.toLocaleDateString();
}
export default ({ label, value, onChange, style, disabled }) => {
	if (typeof value === "string") value = new Date(value);

	return (
		<DatePicker
			label={label}
			formatDate={formatDate}
			onSelectDate={onChange}
			strings={DayPickerStrings}
			value={value}
			placeholder={"Data"}
			styles={style}
			disabled={disabled}
		/>
	);
};
