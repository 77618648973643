import React, { useState } from "react";
import { pure } from "recompose";

const Row = ({ children, child, columns, level, firstLevelOpen }) => {
	const [open, setOpen] = useState(firstLevelOpen ? !level : false);

	if (children) {
		//if (children.length === 1) return children[0];
	}
	return (
		<>
			<tr
				className={
					((children && "clickable ") || "") + (level && `child${level}`)
				}
				onClick={() => setOpen(!open)}
			>
				{columns}
			</tr>
			{open && children}
		</>
	);
};
export default pure(Row);
