import React from "react";
import { Container, ItemContainer } from "./styles";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../Constants";

import { Text } from "office-ui-fabric-react/lib/Text";
import { StackItem } from "office-ui-fabric-react/lib/Stack";
import PanStack from "../../PanStack";
import DropContainer from "../DropContainer";

const Item = ({ value, text, addColumn }) => {
	const [{ isDragging }, dragRef] = useDrag({
		type: ItemTypes.HIDDEN,
		item: { value },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();

			if (dropResult)
				if (item && dropResult.name === "column") {
					addColumn(item.value, dropResult.index);
				}
		},
	});

	return (
		<StackItem>
			<ItemContainer ref={dragRef} isDragging={isDragging}>
				<Text>{text}</Text>
			</ItemContainer>
		</StackItem>
	);
};

const Toolbar = ({ cantRemove, hiddenColumns, addColumn }) => {
	const [{ canDrop, item }, drop] = useDrop({
		accept: ItemTypes.CELL,
		drop: () => ({ name: "hiddenColumns" }),
		collect: (monitor) => ({
			canDrop: monitor.canDrop(),
			item: monitor.getItem(),
		}),
	});
	return (
		<Container ref={drop}>
			{canDrop && !cantRemove.includes(item.value) && (
				<DropContainer iconName={"Hide"} />
			)}
			<PanStack horizontal>
				{hiddenColumns.map(({ key, display }) => (
					<Item
						addColumn={addColumn}
						key={key}
						value={key}
						text={display || key}
					/>
				))}
			</PanStack>
		</Container>
	);
};

export default Toolbar;
