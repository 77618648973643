import styled, { css } from 'styled-components'

export const Container = styled.div`
    position: relative;
    height: 48px;
    padding: 8px;
`
export const ItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    padding: 8px 16px;
    height: 24px;
    border-radius: 24px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: grab;
    ${x =>
        x.isDragging &&
        css`
            border: dashed 1px grey;
            margin: 3px;
        `}
`
