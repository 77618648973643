import { isMobile } from "react-device-detect";

import {
    humanize,
    humanizek,
    profitColor,
    sum,
    tocurrency,
    topercent,
    twodecimals,
    tobps,
} from "./templateUtils";

const textAlign = "right";

const dict = {
    region: {
        name: "Região",
    },
    theme: {
        name: "Tema",
    },
    liquidity: {
        name: "Liquidez",
    },
    book: {
        name: "Book",
    },
    subbook: {
        name: "Sub Book",
    },
    tipo: {
        name: "Tipo",
    },
    expiration: {
        name: "Vencimento",
        value: (value) => {
            const [y, m, d] = value.split("-");
            return `${d}/${m}/${y}`;
        },
    },
    sum_profit: {
        name: "Lucro Pos.",
        value: humanizek,
        textAlign,
    },
    sum_exp: {
        name: "Exp",
        value: humanizek,
        textAlign,
    },
    sum_profit_percent: {
        name: "%",
        value: topercent,
        color: profitColor,
        weight: "bold",
        textAlign,
    },
    sum_exp_percent: {
        name: "%",
        value: topercent,
        color: profitColor,
        weight: "bold",
        textAlign,
    },
    net: {
        name: "Net %",
        value: topercent,
        color: profitColor,
        weight: "bold",
        textAlign,
    },
    volatility: {
        name: "Volatilidade",
        value: topercent,
        color: profitColor,
        weight: "bold",
        textAlign,
    },
    mean: {
        name: "Retorno Médio",
        value: topercent,
        color: profitColor,
        weight: "bold",
        textAlign,
    },
    cumulative: {
        name: "Retorno Acumulado",
        value: topercent,
        color: profitColor,
        weight: "bold",
        textAlign,
    },

    delete: { name: " " },
    symbol: {
        name: "Ativo",
        value: (x) => {
            if (isMobile && x.length > 15) return x.substring(0, 15) + "...";

            return x;
        },
        sort: true,
        maxWidth: "130px",
    },
    sector: { name: "Setor", sort: true, maxWidth: "100px" },
    trade_price: { name: "PX Trade", edit: true, textAlign },
    weight: {
        name: "Peso",
        value: topercent,
        sort: true,
        textAlign,
        onGroup: sum,
    },
    alpha: {
        name: "Alpha",
        value: (v) => tobps(v, 0),
        sort: true,
        textAlign,
        color: profitColor,
        weight: "bold",
        onGroup: sum,
    },
    sum_alpha: {
        name: "Alpha Total",
        value: (v) => tobps(v, 0),
        sort: true,
        color: profitColor,
        weight: "bold",
        textAlign,
    },
    over_under: {
        name: "Over/Under",
        value: topercent,
        sort: true,
        textAlign,
        onGroup: sum,
    },
    delta: { name: "Delta", value: topercent, sort: true, textAlign },
    amount: {
        name: "Quantidade",
        value: humanize,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    amount_adj: {
        name: "Quantidade Ajustada",
        value: humanize,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    change: {
        name: "PX %",
        value: topercent,
        sort: true,
        color: profitColor,
        textAlign,
    },
    change_period: {
        name: "PXP %",
        value: topercent,
        sort: true,
        color: profitColor,
        textAlign,
    },

    previous_close: {
        name: "PX D1",
        value: tocurrency,
        sort: true,
        textAlign,
    },
    price: {
        name: "PX D0",
        value: tocurrency,
        sort: true,
        textAlign,
    },
    underlying: {
        name: "Derivativo",
        value: tocurrency,
        sort: true,
        textAlign,
    },
    trade_amount: {
        name: "Quantidade",
        edit: true,
        textAlign,
    },
    exposition: {
        name: "Exposição",
        value: humanize,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    exposition_percent: {
        name: "Exp %",
        value: topercent,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    exp_adj: {
        name: "Exposição Ajustada",
        value: humanize,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    exp_adj_percent: {
        name: "Exposição Ajustada %",
        value: topercent,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    exp_trade_percent: {
        name: "Exposição %",
        value: topercent,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    profit: {
        name: "Lucro Pos.",
        value: humanize,
        sort: true,
        color: profitColor,
        onGroup: sum,
        textAlign,
        fontWeight: "bold",
    },
    trade_profit: {
        name: "Lucro Trade",
        value: humanize,
        sort: true,
        color: profitColor,
        onGroup: sum,
        textAlign,
    },
    total_profit: {
        name: "Lucro Total",
        value: humanize,
        sort: true,
        color: profitColor,
        onGroup: sum,
        textAlign,
    },

    total_profit_percent: {
        name: "Lucro Total %",
        value: topercent,
        sort: true,
        onGroup: sum,
        textAlign,
    },
    profit_percent: {
        name: "Lucro Pos. %",
        value: topercent,
        onGroup: sum,
        sort: true,
        textAlign,
    },
    change21Day: {
        name: "PX % (21d)",
        value: topercent,
        onGroup: sum,
        sort: true,
        color: profitColor,
        textAlign,
    },
    ibx_perf: {
        name: "IBX",
        value: topercent,
        color: profitColor,
        weight: "bold",
    },
    tracking_error: {
        name: "Tracking Error",
        value: topercent,
    },
    cash: {
        name: "Caixa",
        value: topercent,
    },
};
export default dict;
