import React from 'react'
import { Stack } from 'office-ui-fabric-react/lib/Stack'
import Container from './styles'

const PanStack = props => (
    <Stack>
        <Container>
            <Stack {...props} />
        </Container>
    </Stack>
)

export default PanStack
