import React, { useState, useRef } from "react";

import { Container, ColumnContainer } from "./styles";
import { Text } from "office-ui-fabric-react/lib/Text";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { ActionButton } from "office-ui-fabric-react/lib/Button";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { pure } from "recompose";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../Constants";

const handleEdit = (rowKey, colKey, onEdit, value) => {
	value = onEdit.validate(value);
	onEdit.set({ rowKey, value });
};
const Display = ({ value, display, children }) => (
	<div className="container">
		{children}
		<Text className="text">
			{(value !== null && value !== undefined && String(display)) || "-"}
		</Text>
	</div>
);
const Column = ({
	value,
	display,
	color,
	maxWidth,
	sort,
	moveColumn,
	index,
	removeColumn,
	canRemove,
	textAlign,
}) => {
	const ref = useRef();

	const [{ isDragging }, dragRef] = useDrag({
		type: ItemTypes.CELL,
		item: { index, value },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			console.log(dropResult);
			console.log(item);
			if (!dropResult) return;
			if (canRemove) {
				if (dropResult.name === "hiddenColumns") return removeColumn(value);
			}
			if (dropResult.dropEffect === "move") {
				if (item.index === dropResult.index) return;
				moveColumn(item.index, dropResult.index);
				item.index = dropResult.index;
			}
		},
	});
	const [{ side }, dropHidden] = useDrop({
		accept: [ItemTypes.HIDDEN, ItemTypes.CELL],
		drop: () => ({ name: "column", index }),
		collect: (monitor) => {
			let side = -1;

			if (!monitor.isOver())
				return {
					side: 0,
				};
			const draggedItem = monitor.getItem();
			if (draggedItem) {
				if (draggedItem.index < index) side = 1;
				if (draggedItem.index === index) side = 0;
			}
			return {
				side,
			};
		},
	});
	/*

	const [{ isDragging }, dragRef] = useDrag({
		type: ItemTypes.HIDDEN,
		item: { value },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();

			if (dropResult)
				if (item && dropResult.name === "column") {
					addColumn(item.value, dropResult.index);
				}
		},
	});
	*/

	const [, drop] = useDrop({
		accept: ItemTypes.CELL,
		collect: (monitor) => ({
			canDrop: !!monitor.canDrop(),
		}),
		/*
		hover(item, monitor) {
			if (!ref.current) return;

			const dragIndex = item.index;
			const hoverIndex = index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return;

			const hoverBoundingRect = ref.current.getBoundingClientRect();

			const hoverMiddleX =
				(hoverBoundingRect.right - hoverBoundingRect.left) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;

			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

			moveColumn(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		*/
	});
	dragRef(drop(dropHidden(ref)));

	return (
		<ColumnContainer
			ref={ref}
			isDragging={isDragging}
			side={side}
			color={color}
			textAlign={textAlign}
			maxWidth={maxWidth}
		>
			<Display value={value} display={display || value}>
				{!!sort && (
					<Icon onClick={sort} iconName="Sort" className="ms-sortIcon" />
				)}
			</Display>
		</ColumnContainer>
	);
};

const Cell = ({
	value,
	display,
	color,
	maxWidth,
	textAlign,
	fontWeight,
	onEdit,
	colKey,
	rowKey,
}) => {
	const [cellState, setState] = useState({ rowKey, colKey, value });

	const button = () => (
		<Stack horizontalAlign="center">
			<ActionButton
				styles={{
					root: {
						height: 0,
					},
				}}
				onClick={() => value.action({ rowKey })}
				iconProps={{ iconName: value.iconName }}
			/>
		</Stack>
	);
	const normal = () =>
		onEdit ? (
			<TextField
				underlined
				styles={{
					fieldGroup: {
						backgroundColor: "transparent",
					},
					field: {
						color: "white",
						textAlign: textAlign || "unset",
					},
				}}
				value={value}
				onChange={(e) => handleEdit(rowKey, colKey, onEdit, e.target.value)}
			/>
		) : (
			<Display value={value} display={display} />
		);

	return (
		<Container
			fontWeight={fontWeight}
			textAlign={textAlign}
			color={color}
			maxWidth={maxWidth}
		>
			{(value !== null && typeof value == "object" && button()) || normal()}
		</Container>
	);
};
export default {
	Cell: pure(Cell),
	Column: pure(Column),
};
