export const chartTypes = {
    BAR: "bar",
};
export const chartHeights = {
    SMALLER: 150,
    SMALL: 200,
    MEDIUM: 250,
    BIG: 300,
};
export const formatISODate = (date) => {
    try {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    } catch (error) {
        return;
    }
};
export const mountOptions = (
    categories,
    id = "basic-bar",
    formatter = (value) => value
) => ({
    chart: {
        id,
    },
    xaxis: {
        labels: {
            formatter: function (value, timestamp) {
                return formatISODate(value);
            },
        },
        categories,
    },
    yaxis: {
        labels: {
            formatter,
        },
    },
    stroke: {
        curve: "smooth",
    },
});

export const mountSeries = (name, data) => ({
    name,
    data,
});
export const chartTitleType1 = (text) => ({
    text,
    style: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "16px",
    },
});
export const chartType1 = (
    series,
    categories,
    group = undefined,
    showLabels = true,
    title = undefined,
    showToolbar = true
) => ({
    options: {
        chart: {
            id: "line",
            group,

            toolbar: {
                show: showToolbar,
            },
        },
        title: chartTitleType1(title),
        xaxis: {
            labels: {
                formatter: function (value, timestamp) {
                    return formatISODate(value);
                },
                show: showLabels,
                rotate: -15,
            },
            categories,
        },
        yaxis: {
            labels: {
                formatter: (value) =>
                    typeof value === "number"
                        ? (value * 100).toFixed(2) + "%"
                        : value,
            },
        },
        stroke: {
            width: 2,
        },
    },

    series,
});

export const chartType2 = (
    series,
    categories,
    group = undefined,
    showLabels = true,
    title = undefined,
    showToolbar = true
) => ({
    options: {
        chart: {
            id: "basic-bar",
            group,
            toolbar: {
                show: false,
            },
        },
        title: chartTitleType1(title),
        xaxis: {
            labels: {
                show: showLabels,
            },
            categories,
        },
        yaxis: {
            labels: {
                formatter: (value) => (value * 100).toFixed(2) + "%",
            },
        },
        dataLabels: {
            formatter: function (val, opts) {
                return (val * 100).toFixed(2);
            },
        },
    },

    series,
});
