import {
	ActionButton,
	BaseButton,
	DefaultButton,
	IconButton,
	PrimaryButton,
	Stack,
	TextField,
} from "office-ui-fabric-react";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../components/Logo";
import { useUserActions, useUserState } from "../../UserContext";
import "./index.css";

export default function SignIn() {
	const { replace, location } = useHistory();
	const { loading, authorization } = useUserState();
	const { sign_in } = useUserActions();
	const user_name_ref = useRef();
	const password_ref = useRef();

	function submit() {
		const user_name = user_name_ref.current.value;
		const password = password_ref.current.value;

		sign_in(user_name, password);
	}
	function handleKeyDown(e) {
		console.log(e);
		if (e.key === "Enter") {
			submit();
		}
	}

	useEffect(() => {
		if (!authorization) return;
		if (location.pathname !== "/sign_in") return;

		replace("/");
	}, [authorization, location]);

	function sign_up() {
		replace("/sign_up");
	}
	return (
		<div className={"sign_in-form"} onKeyDown={handleKeyDown}>
			<Logo />
			<Stack>
				<TextField
					disabled={loading}
					componentRef={user_name_ref}
					label="Usuário"
					type="username"
				/>
				<TextField
					disabled={loading}
					componentRef={password_ref}
					label="Senha"
					type="password"
				/>
				<PrimaryButton
					disabled={loading}
					style={{ marginTop: 8 }}
					onClick={submit}
					text="Acessar"
				/>
				<ActionButton
					iconProps={{
						iconName: "AddFriend",
					}}
					text={"Solicitar Acesso"}
					onClick={sign_up}
				/>
			</Stack>
		</div>
	);
}
