import { isMobile } from "react-device-detect";

import {
	humanize,
	humanizek,
	profitColor,
	sum,
	first,
	tocurrency,
	topercent,
	twodecimals,
	tobps,
} from "./templateUtils";

const textAlign = "right";

export default {
	position_pnl_alpha_ibx_pct: {
		name: "Alpha Total",
		value: (v) => tobps(v, 0),
		sort: true,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	pnl: {
		name: "PnL",
		value: humanizek,
		textAlign,
	},
	equity_exposure: {
		name: "Exposição",
		value: humanizek,
		textAlign,
	},
	pnl_pct: {
		name: "%",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	equity_exposure_pct: {
		name: "%",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	fx_exposure_usdbrl_pct: {
		name: "USDBRL %",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
};
