export function table_to_object(data, columns, renamed_columns) {
	if (columns === undefined) columns = data.columns;
	if (renamed_columns === undefined) renamed_columns = columns;
	const final = [];

	for (const row of data.data) {
		const item = {};
		for (const column of columns) {
			const i = data.columns.indexOf(column);
			if (i === -1) continue;

			const renamed_column = renamed_columns[columns.indexOf(column)];
			item[renamed_column] = row[i];
		}
		final.push(item);
	}

	return final;
}

export function join_tables(data, data1, key, key1) {
	if (!key1) key1 = key;

	let other_columns = [...data1.columns];
	other_columns.splice(other_columns.indexOf(key1), 1);

	const next_columns = [...data.columns, ...other_columns];
	const next_data = [];

	const nan_values = data1.columns.map(() => undefined);

	for (const row of data.data) {
		let other_values = nan_values;

		const key_value = row[data.columns.indexOf(key)];
		const key1_i = data1.columns.indexOf(key1);

		for (const other_row of data1.data) {
			if (other_row[key1_i] !== key_value) continue;

			other_values = [...other_row];
			other_values.splice(key1_i, 1);
			break;
		}

		const next_row = [...row, ...other_values];

		next_data.push(next_row);
	}

	return { columns: next_columns, data: next_data };
}

export function filter1(table, key, values, how = "ignore") {
	const i = table.columns.indexOf(key);
	const next_rows = [];

	for (const row of table.data) {
		const includes = values.includes(row[i]);
		if (how === "ignore" && includes) continue;
		if (how === "keep" && !includes) continue;

		next_rows.push(row);
	}

	return { columns: table.columns, data: next_rows };
}

export function filter(table, keys, values, how = "ignore") {
	const index = keys.map((key) => table.columns.indexOf(key));
	const next_rows = [];

	for (const row of table.data) {
		let match = 0;
		for (const i of index) {
			const includes = values.includes(row[i]);
			if (how === "ignore" && includes) continue;
			if (how === "keep" && !includes) continue;
			match++;
		}
		if (match == 0) continue;

		next_rows.push(row);
	}

	return { columns: table.columns, data: next_rows };
}

export function sum(table, columns) {
	const x = columns.map((x) => table.columns.indexOf(x));

	const data = {};
	for (const row of table.data) {
		for (const i in x) {
			const column = columns[i];
			const value = row[x[i]];
			if (isNaN(value)) continue;

			if (data[column] === undefined) {
				data[column] = value;
				continue;
			}
			data[column] += value;
		}
	}

	return data;
}
