import React, { useContext, useEffect, useState, useMemo } from "react";
import { createContext } from "react";
import createPersistedState from "use-persisted-state";
import { sign_in, sign_up } from "./lib/api";

const UserState = createContext();
const UserActions = createContext();

const useAuthorizationState = createPersistedState("JLYWeskAlG");

export default function UserContext({ children }) {
	const [authorization, setAuthorization] = useAuthorizationState(null);
	const [loading, setLoading] = useState(false);

	async function _sign_in(user_name, password) {
		setLoading(true);
		sign_in(user_name, password)
			.then((token) => {
				if (!token)
					return alert("Algo inesperado aconteceu, tente novamente.");
				setAuthorization(token);
			})
			.catch(alert)
			.then(() => setLoading(false));

		setLoading(false);
	}

	async function _sign_up(name, user_name, password, password_check) {
		setLoading(true);
		sign_up(name, user_name, password, password_check)
			.then(() => {
				alert("Aguarde a aprovação de um administrador");
			})
			.catch(alert)
			.then(() => setLoading(false));

		setLoading(false);
	}

	async function _sign_out() {
		setAuthorization(null);
	}
	return (
		<UserState.Provider
			value={{
				authorization,
				loading,
			}}
		>
			<UserActions.Provider
				value={{
					sign_in: _sign_in,
					sign_up: _sign_up,
					sign_out: _sign_out,
				}}
			>
				{children}
			</UserActions.Provider>
		</UserState.Provider>
	);
}

export const useUserState = () => useContext(UserState);
export const useUserActions = () => useContext(UserActions);
