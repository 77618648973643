import React, { useMemo, useRef } from "react";

import Table from "../../components/Table";
import template from "../../services/tableTemplate";
import infoTemplate from "../../services/infoTemplate";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import GeneralDisplayFabric from "../../components/GeneralDisplayFabric";
import PanStack from "../../components/PanStack";
import { CommandBar, constructKeytip, Stack } from "office-ui-fabric-react";
import { isMobile } from "react-device-detect";
import { usePortfolioState, usePortfolioActions } from "../../PortfolioContext";
import DatePicker from "../../components/DatePicker";
import dropdownHandler from "../../lib/dropdownHandler";
import * as pd from "../../lib/table";
import {
	create_trading_desks_options,
	create_books_options,
	create_sheet_name,
	create_report_types_options,
} from "../../lib/utils";
import { stack } from "../../styles";
import * as defaults from "../../lib/defaults";

const styles = {
	dropdown: { width: 150 },
};

const book = "book_1,book_2,book_3,book_4,book_5";
const options = [
	{
		key: `basic_report_ticker,${book},basic_report_ticker`,
		text: "Papel",
	},
	{ key: `object_ticker,${book},basic_report_ticker`, text: "Ativo Objeto" },
	{ key: `${book},basic_report_ticker`, text: "Book" },
	{
		key: `inter_analyst,basic_report_ticker,${book}`,
		text: "Analista",
	},
	{
		key: `rps_subsetor,basic_report_ticker,${book}`,
		text: "Setor",
	},
	{
		key: `rps_setor,basic_report_ticker,${book}`,
		text: "Tema",
	},
	{
		key: `country_risk,basic_report_ticker,${book}`,
		text: "País",
	},
];

export default function Home() {
	let {
		trading_desks,
		report_date,
		trading_desk_id,
		report_type,
		books,
		group,
		trades_only,
		loading,
		columns,
		data,
	} = usePortfolioState();

	const {
		set_report_date,
		set_trading_desk_id,
		set_report_type,
		set_books,
		set_group,
		set_trades_only,
		set_columns,
		reset_preferences,
	} = usePortfolioActions();

	const books_options = create_books_options(data?.trading_desk_books);

	const report_types_options = create_report_types_options(data?.is_final);

	const _columns = useMemo(
		() => columns[trading_desk_id] || defaults.columns.overview,
		[columns, trading_desk_id]
	);

	const _group = useMemo(() => {
		let _group = group[trading_desk_id] || options[0].key;
		if (_group) {
			const first_level = _group.split(",")[0];
			const option = options.find((x) => x.key.startsWith(first_level));
			if (option) return option.key;

			return options[0].key;
		}
		return _group;
	}, [group, trading_desk_id]);

	const _books = useMemo(() => {
		let selected_books = books[trading_desk_id];
		const default_books = data?.trading_desk_books || [];
		if (selected_books !== undefined) {
			if (!selected_books.length) {
				selected_books = default_books;
			}
		}
		return selected_books || default_books;
	}, [books, data]);

	const { table_data, view } = useMemo(() => {
		let table_data = data?.overview;
		if (loading || !table_data?.data?.length) return { view: [] };

		const alpha_column = "position_pnl_alpha_ibx_pct";
		const has_alpha = _columns?.includes(alpha_column);

		table_data = pd.join_tables(table_data, data.books, "book_id");

		table_data = pd.filter(
			table_data,
			["book_1"],
			[..._books, "IBX100 comprado", "IBX100 vendido"],
			"keep"
		);
		if (trades_only && parseInt(report_type)) {
			table_data = pd.filter(
				table_data,
				["trades_equity_exposure_pct", "trades_pnl_pct"],
				[null, 0]
			);
		}

		let view = pd.join_tables(table_data, data.products, "product_id");

		view = pd.filter(
			view,
			["amount", "pnl_pct", ...(has_alpha ? [alpha_column] : [])],
			[null, 0]
		);

		view = pd.filter(view, ["product_class"], ["Benchmark Products"]);

		view = pd.join_tables(view, data.prices, "product_id");
		view = pd.join_tables(view, data.prices_periods, "product_id");
		view = pd.join_tables(view, data.sectors, "alias_ticker");
		view = pd.join_tables(view, data.weights, "ticker");

		view = pd.table_to_object(view);

		return { view, table_data };
	}, [
		loading,
		data,
		trading_desk_id,
		report_type,
		_columns,
		_books,
		trades_only,
	]);

	const info = useMemo(() => {
		if (!table_data) return {};
		let columns_to_sum = [
			"equity_exposure",
			"equity_exposure_pct",
			"pnl",
			"pnl_pct",
		];
		if (_columns?.includes("fx_exposure_usdbrl_pct")) {
			columns_to_sum = ["fx_exposure_usdbrl_pct", ...columns_to_sum];
		}
		let info = {};
		if (_columns?.includes("position_pnl_alpha_ibx_pct")) {
			let alpha_view = pd.join_tables(
				table_data,
				data.products,
				"product_id"
			);
			alpha_view = pd.filter(
				alpha_view,
				["product_class"],
				["Benchmark Products"]
			);
			info = {
				...info,
				...pd.sum(alpha_view, ["position_pnl_alpha_ibx_pct"]),
			};
		}

		if (!info.position_pnl_alpha_ibx_pct)
			delete info.position_pnl_alpha_ibx_pct;
		info = { ...info, ...pd.sum(table_data, columns_to_sum) };

		return info;
	}, [table_data, _columns]);

	const trading_desks_options = create_trading_desks_options(trading_desks);

	const table = useRef({});

	function handle_columns_changed(x) {
		set_columns((y) => ({ ...y, [trading_desk_id]: x }));
	}

	function handle_group_changed(x) {
		set_group((y) => ({ ...y, [trading_desk_id]: x }));
	}

	function handle_books_changed(x) {
		let next_books = [..._books];
		const i = next_books.indexOf(x);
		if (i === -1) {
			next_books.push(x);
		} else {
			next_books.splice(i, 1);
		}
		set_books((y) => ({ ...y, [trading_desk_id]: next_books }));
	}

	return (
		<>
			{isMobile ? (
				<>
					<PanStack {...stack}>
						<Dropdown
							selectedKey={_group}
							onChange={dropdownHandler(handle_group_changed)}
							placeholder=""
							label="Agrupar"
							options={options}
							styles={styles}
							disabled={loading}
							styles={{ root: { width: 60 } }}
						/>
						<Dropdown
							label="Books"
							multiSelect
							options={books_options}
							placeholder={"Todos"}
							styles={{
								root: { width: 60 },
								dropdownItemsWrapper: { maxHeight: 450 },
							}}
							selectedKeys={_books}
							onChange={dropdownHandler(handle_books_changed)}
							disabled={loading}
							ariaLabel=""
						/>

						<Dropdown
							selectedKey={report_type}
							onChange={dropdownHandler(set_report_type)}
							placeholder=""
							label="Relatório"
							options={report_types_options}
							styles={styles}
							disabled={loading}
							styles={{ root: { width: 110 } }}
						/>
						<DatePicker
							label={"Data"}
							value={report_date}
							onChange={set_report_date}
							disabled={loading}
						/>
						<Dropdown
							label="Fundo"
							selectedKey={trading_desk_id}
							onChange={dropdownHandler(set_trading_desk_id)}
							options={trading_desks_options}
							styles={{ root: { width: 120 } }}
							disabled={loading}
						/>
						<CommandBar
							overflowItems={[
								{
									key: "reset_preferences",
									name: "Reiniciar Preferências",
									iconProps: {
										iconName: "ClearFilter",
									},
									onClick: reset_preferences,
								},
								{
									key: "saveToExcel",
									name: "Todas as Carteiras",
									disabled: true,
									iconProps: {
										iconName: "ExcelDocument",
									},
									onClick: () => {},
								},
								{
									key: "saveToExcel1",
									name: "Carteira Atual",
									iconProps: {
										iconName: "ExcelDocument",
									},
									onClick: () =>
										table.current.toExcel(
											table.current.data,
											create_sheet_name(
												data?.trading_desks,
												trading_desk_id,
												report_date
											)
										),
								},
							]}
						/>
					</PanStack>
					<Stack {...stack}>
						<Toggle
							defaultChecked={trades_only}
							label="Apenas Trades"
							onChange={(e, value) => set_trades_only(value)}
							disabled={loading || !parseInt(report_type)}
						/>
						<GeneralDisplayFabric
							loading={loading}
							info={info}
							template={infoTemplate}
							separation={2}
						/>
					</Stack>
				</>
			) : (
				<>
					<PanStack {...stack}>
						<Dropdown
							selectedKey={_group}
							onChange={dropdownHandler(handle_group_changed)}
							placeholder=""
							label="Agrupar"
							options={options}
							styles={styles}
							disabled={loading}
							styles={{ root: { width: 110 } }}
						/>
						<Dropdown
							label="Books"
							multiSelect
							options={books_options}
							placeholder={"Todos"}
							style={{ width: 120 }}
							styles={{
								dropdownItemsWrapper: { maxHeight: 450 },
							}}
							selectedKeys={_books}
							onChange={dropdownHandler(handle_books_changed)}
							disabled={loading}
							ariaLabel=""
						/>

						<Toggle
							defaultChecked={trades_only}
							label="Apenas Trades"
							onChange={(e, value) => set_trades_only(value)}
							disabled={loading || !parseInt(report_type)}
						/>

						<div style={{ flex: 1 }} />
						<GeneralDisplayFabric
							loading={loading}
							info={info}
							template={infoTemplate}
							separation={2}
						/>
						<Dropdown
							selectedKey={report_type}
							onChange={dropdownHandler(set_report_type)}
							placeholder=""
							label="Relatório"
							options={report_types_options}
							styles={styles}
							disabled={loading}
							styles={{ root: { width: 110 } }}
						/>
						<DatePicker
							label={"Data"}
							value={report_date}
							onChange={set_report_date}
							disabled={loading}
							style={{
								wrapper: {
									width: 120,
								},
							}}
						/>
						<Dropdown
							label="Fundo"
							selectedKey={trading_desk_id}
							onChange={dropdownHandler(set_trading_desk_id)}
							options={trading_desks_options}
							styles={{ root: { width: 120 } }}
							disabled={loading}
						/>
						<CommandBar
							overflowItems={[
								{
									key: "reset_preferences",
									name: "Reiniciar Preferências",
									iconProps: {
										iconName: "ClearFilter",
									},
									onClick: reset_preferences,
								},
								{
									key: "saveToExcel",
									name: "Todas as Carteiras",
									disabled: true,
									iconProps: {
										iconName: "ExcelDocument",
									},
									onClick: () => {},
								},
								{
									key: "saveToExcel1",
									name: "Carteira Atual",
									iconProps: {
										iconName: "ExcelDocument",
									},
									onClick: () =>
										table.current.toExcel(
											table.current.data,
											create_sheet_name(
												data?.trading_desks,
												trading_desk_id,
												report_date
											)
										),
								},
							]}
						/>
					</PanStack>
				</>
			)}

			<Table
				ref={table}
				rowKey="ticker"
				canModify={!isMobile}
				cantRemove={["ticker"]}
				group={_group?.split(",")}
				template={template}
				data={view}
				orderedColumns={_columns}
				available_columns={defaults.columns.available}
				onColumnsChanged={handle_columns_changed}
				loading={loading}
			/>
		</>
	);
}
