import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Home from "./routes/Home";
import routes from "./routes";
import PortfolioContext from "./PortfolioContext";
import RiskContext from "./RiskContext";
import RiskReporting from "./routes/RiskReporting";
import Tabs from "./components/Tabs";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Resume from "./routes/Resume";
import { useUserState } from "./UserContext";
import SignIn from "./routes/SignIn";
import SignUp from "./routes/SignUp";

export default function App() {
	const { authorization } = useUserState();
	return (
		<div className="app">
			<BrowserRouter>
				<DndProvider backend={HTML5Backend}>
					{authorization && <Tabs routes={routes} />}
					<Switch>
						<PortfolioContext>
							<Route path="/sign_in" exact component={SignIn} />
							<Route path="/sign_up" exact component={SignUp} />

							<Route path="/" exact component={Home} />
							<Route path="/resume" exact component={Resume} />

							<RiskContext>
								<Route
									path="/risk_reporting"
									exact
									component={RiskReporting}
								/>
							</RiskContext>
							{!authorization && <Redirect exact to="/sign_in" />}
						</PortfolioContext>
					</Switch>
				</DndProvider>
			</BrowserRouter>
		</div>
	);
}
/*				

*/
