export const sum = (a) => a.reduce((t, x) => t + x, 0);
export const mean = (a) => sum(a) / a.length;
export const min = (a) => {
    let m = undefined;
    for (let x of a) {
        if (m === undefined) {
            m = x;
        } else {
            if (x < m) {
                m = x;
            }
        }
    }
    return m === undefined ? 0 : m;
};
export const max = (a) => {
    let m = undefined;
    for (let x of a) {
        if (m === undefined) {
            m = x;
        } else {
            if (x > m) {
                m = x;
            }
        }
    }
    return m === undefined ? 0 : m;
};

export const histogram_old = (a, bins = 25) => {
    const mi = min(a);
    const ma = max(a);
    const width = (ma - mi) / (bins - 1);

    const data = [];
    const intervals = [];

    let windowStart = mi;
    let windowEnd = mi + width;

    for (let i = 0; i < bins; i++) {
        let matches = 0;
        for (let val of a) {
            if (val >= windowStart && val <= windowEnd) {
                matches++;
            }
        }
        data.push(matches);
        intervals.push([windowStart, windowEnd]);
        windowStart += width;
        windowEnd += width;
    }
    return { data, intervals };
};
export const histogram = (a, interval = 0.005) => {
    let data = [];
    let intervals = [];
    let base = [];
    let percentage = -0.5;
    while (percentage < 0.5) {
        percentage += interval;
        base.push(percentage);
    }

    for (const i in base) {
        if (i == 0) continue;
        let matches = 0;
        const start = base[i - 1];
        const end = base[i];

        for (const val of a) {
            if (val > start && val < end) {
                matches++;
            }
        }
        data.push(matches);
        intervals.push([start, end]);
    }
    const vmin = Math.abs(min(a));
    const vmax = Math.abs(max(a));
    let s = 0;
    let m = Math.round(data.length / 2);
    let e = data.length;
    for (const i in data) {
        if (data[i] > 0) {
            s = i;
            break;
        }
    }
    for (let i = data.length - 1; i > 0; i--) {
        if (data[i] > 0) {
            e = i;
            break;
        }
    }
    if (vmin < vmax) {
        s = m - (e - m);
    } else {
        e = m - s + m;
    }
    s -= 2;
    if (s < 0) s = 0;

    data = data.slice(s, e);
    intervals = intervals.slice(s, e);

    return { data, intervals };
};
export const standardDeviation = (array) => {
    const n = array.length;
    if (!n) return 0;

    const sum = array.reduce((a, b) => a + b);
    const mean = sum / n;

    return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
            (n - 1)
    );
};

export const acumulate = (a) => a.reduce((t, v) => t * (1 + v), 1) - 1;

export const accumulate = (a) => {
    let final = 1;

    for (const v of a) {
        final = final * (1 + v);
    }

    return final - 1;
};

export default {
    sum,
    acumulate,
    accumulate,
    mean,
    min,
    max,
    histogram,
    standardDeviation,
};
