import { isMobile } from "react-device-detect";

import {
	humanize,
	humanizek,
	profitColor,
	sum,
	first,
	tocurrency,
	topercent,
	twodecimals,
	tobps,
} from "./templateUtils";

const textAlign = "right";

export default {
	description: { name: "Bolsas e Moedas", sort: true },

	rps_name: { name: "Fundo", sort: true },
	dtd_pct: {
		name: "PX %",
		value: topercent,
		sort: true,
		color: profitColor,
		onGroup: first,
		textAlign,
	},
	mtd_pct: {
		name: "PX MTD %",
		value: topercent,
		sort: true,
		color: profitColor,
		onGroup: first,
		textAlign,
	},
	ytd_pct: {
		name: "PX YTD %",
		value: topercent,
		sort: true,
		color: profitColor,
		onGroup: first,
		textAlign,
	},
	price_d0: {
		name: "PX D0",
		value: tocurrency,
		sort: true,
		textAlign,
		onGroup: first,
	},
	equity_exposure_pct: {
		name: (isMobile && "Exp %") || "Exposição %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	dv01_pct: {
		name: "DV01 (bps)",
		value: (x) => tobps(x, 1),
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	position_pnl_pct: {
		name: "Pos. PnL %",
		value: topercent,
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	trades_pnl_pct: {
		name: "Trades PnL %",
		value: topercent,
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	pnl_pct: {
		name: "PnL %",
		value: topercent,
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	pnl: {
		name: "PnL",
		value: humanize,
		sort: true,
		color: profitColor,
		onGroup: sum,
		textAlign,
		fontWeight: "bold",
	},
};
