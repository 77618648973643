import { isMobile } from "react-device-detect";

const routes = [
	{
		name: "Resumo",
		path: "/resume",
		exact: true,
	},
	{
		name: "Position Reporting",
		path: "/",
		exact: true,
	},
	{
		name: "Risk Reporting",
		path: "/risk_reporting",
		exact: true,
	},
];

export default routes;
