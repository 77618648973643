import { DropdownMenuItemType } from "office-ui-fabric-react";
import React, { createContext, useContext, useState, useEffect } from "react";
import tempData from "./services/tempdata";
const Context = createContext();
const isDev = process.env.NODE_ENV === "development";

const noData = {
	usedWalletDate: undefined,
	patrimony: 0,
	prices: [],
	report: [],
	profit_series: {
		profit: [],
		profit_percent: [],
	},
	dates: [],
};
export default function RiskContext({ children }) {
	const [trading_desk_id, set_trading_desk_id] = useState(10);
	const [bookSelection, setbookSelection] = useState([]);
	//const [bookSelections, setbookSelections] = useState([nobookSelection]);
	const [walletDate, setWalletDate] = useState(null);

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState(new Date());
	const [viewDate, setViewDate] = useState();
	const [loading, setLoading] = useState(false);
	const [viewDocument, setViewDocument] = useState(false);
	let [
		{ usedWalletDate, patrimony, prices, report, profit_series, dates },
		setData,
	] = useState(noData);

	const [books, bookList] = React.useMemo(() => {
		const books = {};
		const bookList = [];

		const frozen = report
			.filter((x) => x.exposition)
			.sort((a, b) => a.book.localeCompare(b.book));

		for (const i in frozen) {
			const item = frozen[i];
			const bookPath = [item.book, item.subbook]
				.filter((x) => x)
				.join(" - ");
			if (books[bookPath]) continue;

			if (!books[item.book]) {
				books[item.book + "_divider"] = {
					key: i - 1,
					text: item.book,
					itemType: DropdownMenuItemType.Header,
				};
				books[item.book] = {
					key: item.book,
					text: "Selecionar Todos",
					value: item.book,
					type: "group",
				};
			}
			bookList.push(bookPath);
			books[bookPath] = {
				key: bookPath,
				text: item.subbook,
				value: bookPath,
			};
		}

		return [Object.values(books), bookList];
	}, [report]);

	function setValue(key, value) {
		switch (key) {
			case "trading_desk_id":
				set_trading_desk_id(value);
				setData(noData);
				break;

			case "walletDate":
				setWalletDate(value);
				setData(noData);

				break;

			case "startDate":
				setStartDate(value);
				setData(noData);

				break;

			case "endDate":
				setEndDate(value);
				setData(noData);
				break;

			case "viewDate":
				setViewDate(value);
				break;

			case "data":
				if (!value) setData(noData);
				else setData(value);

				break;

			case "bookSelection":
				if (value.type === "group") {
					if (bookSelection.includes(value.key)) {
						return setbookSelection(
							bookSelection.filter(
								(x) => !x.startsWith(value.key)
							)
						);
					} else {
						const groupSelections = bookList
							.filter((x) => x.startsWith(value.key))
							.filter((x) => !bookSelection.includes(x));
						return setbookSelection([
							...bookSelection,
							...groupSelections,
							value.key,
						]);
					}
				}
				if (!bookSelection.includes(value.key))
					return setbookSelection([...bookSelection, value.key]);
				else
					return setbookSelection(
						bookSelection.filter((x) => x !== value.key)
					);
				break;
			case "loading":
				setLoading(value);
				break;
			case "viewDocument":
				setViewDocument(value);
				break;
			default:
				break;
		}
	}
	/*
    useEffect(() => {
        Api()
            .bookSelections()
            .then((data) => [
                nobookSelection,
                ...data.map((text) => ({
                    key: text,
                    text,
                })),
            ])
            .then(setbookSelections)
            .catch(() => {});
    }, []);
    */

	if (typeof usedWalletDate === "string") {
		usedWalletDate = new Date(usedWalletDate);
		usedWalletDate.setHours(24);
	}
	return (
		<Context.Provider
			value={{
				trading_desk_id,
				bookSelection,
				walletDate: usedWalletDate || walletDate,
				startDate,
				patrimony,
				prices,
				report,
				profit_series,
				dates,
				endDate,
				viewDate,
				setValue,
				loading,
				viewDocument,
				books,
				bookList,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export const useRiskReportingStore = () => useContext(Context);
