import React, { useContext, useEffect, useState, useMemo } from "react";
import { createContext } from "react";
import * as defaults from "./lib/defaults";
import createPersistedState from "use-persisted-state";
import { fetch_report } from "./lib/api";
import { useUserState } from "./UserContext";

const PortfolioState = createContext();
const PortfolioActions = createContext();

const useTradingDesksState = createPersistedState("trading_desks");
const useBooksState = createPersistedState("books");
const useTradingDeskState = createPersistedState("trading_desk_id");
const useGroupState = createPersistedState("group");
const useColumnsState = createPersistedState("columns");

const default_report_date = new Date();

const MANUAL_START = "MANUAL_START";
const MANUAL_END = "MANUAL_END";
const AUTO_START = "AUTO_START";
const AUTO_END = "AUTO_END";

let refresh_state;
let refresh_interval;
let updated_at;

function set_refresh_state(state) {
	refresh_state = state;
}

export default function PortfolioContext({ children }) {
	const [trading_desks, set_trading_desks] = useTradingDesksState();
	const [trading_desk_id, set_trading_desk_id] = useTradingDeskState(10);
	const [report_date, set_report_date] = useState(default_report_date);
	const [report_type, set_report_type] = useState(2);
	const [data, set_data] = useState();

	const [group, set_group] = useGroupState({});
	const [books, set_books] = useBooksState({});
	const [trades_only, set_trades_only] = useState(false);

	const [loading, set_loading] = useState(!data ? true : false);
	const [columns, set_columns] = useColumnsState({});

	const { authorization } = useUserState();

	function reset_preferences() {
		set_columns({});
		set_group({});
		set_books({});
	}

	useEffect(() => {
		if (!authorization) return;
		set_refresh_state(MANUAL_START);
		set_loading(true);
		fetch_report(report_date, trading_desk_id, report_type)
			.then((data) => {
				if (!data) {
					set_data();
					return set_refresh_state(MANUAL_END);
				}
				updated_at = data.updated_at;
				set_trading_desks(data.trading_desks);
				set_data(data);
				set_refresh_state(MANUAL_END);
			})
			.catch(() => {})
			.then(() => {
				set_loading(false);
			});
	}, [report_date, trading_desk_id, report_type, authorization]);

	useEffect(() => {
		clearInterval(refresh_interval);
		if (!authorization) return;
		refresh_interval = setInterval(() => {
			if (![MANUAL_END, AUTO_END].includes(refresh_state)) return;
			set_refresh_state(AUTO_START);
			fetch_report(report_date, trading_desk_id, report_type, updated_at)
				.then((data) => {
					if (!data) return set_refresh_state(AUTO_END);
					if (refresh_state !== AUTO_START) return;

					updated_at = data.updated_at;
					set_trading_desks(data.trading_desks);
					set_data(data);
					set_refresh_state(AUTO_END);
				})
				.catch(() => {});
		}, 5 * 1000);
	}, [data, report_date, trading_desk_id, report_type, authorization]);

	const _report_type = useMemo(() => (data?.is_final ? 0 : report_type));

	return (
		<PortfolioState.Provider
			value={{
				report_date,
				trading_desk_id,
				report_type: _report_type,
				loading,
				books,
				group,
				trades_only,
				loading,
				columns,
				data,
				trading_desks,
			}}
		>
			<PortfolioActions.Provider
				value={{
					set_report_date,
					set_trading_desk_id,
					set_report_type,
					set_books,
					set_group,
					set_trades_only,
					set_columns,
					reset_preferences,
				}}
			>
				{children}
			</PortfolioActions.Provider>
		</PortfolioState.Provider>
	);
}

export const usePortfolioState = () => useContext(PortfolioState);
export const usePortfolioActions = () => useContext(PortfolioActions);
