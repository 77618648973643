import styled from 'styled-components'

export const Container = styled.div`
    position: absolute;
    display: flex;
    left: 8px;
    right: 8px;
    top: 8px;
    bottom: 8px;
    justify-content: center;
    align-items: center;
    color: grey;
    background-color: rgba(255, 255, 255, 0.75);
    flex: 1;
    padding: 8px;
    border-radius: 8px;
    border: dashed 2px grey;
`
