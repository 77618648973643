import React from 'react'
import { Icon } from 'office-ui-fabric-react/lib/Icon'
import { Container } from './styles'

const DropContainer = ({ iconName }) => {
    return (
        <Container>
            <Icon iconName={iconName} />
        </Container>
    )
}
export default DropContainer
