import XLSX from "xlsx";
import { get } from "safe-properties";
const groupBy = (items, key, dict, alt) => {
	const grouped = items.reduce((result, item) => {
		let group = result.find((x) => x[key] === item[key]);

		if (!group) {
			if (item[key] === null) {
				result.push(item);
				return result;
			}
			group = Object.entries(item).reduce(
				(g, i) => ({
					...g,
					[key]: item[key] || null,
				}),
				{ children: [] }
			);
			//alt && (group[alt] = group[key]);
			result.push(group);
		}
		dict &&
			Object.keys(group).forEach((k) => {
				const i = dict[k];
				if (i) {
					if (i["onGroup"]) {
						group[k] = i["onGroup"](group[k], item[k]);
					}
				}
			});

		group.children.push(item);

		return result;
	}, []);

	if (alt) {
		return grouped.map((g) => {
			//delete g[key]
			return g;
		});
	}
	return grouped;
};
const getColumns = (data = [], available_columns = []) => {
	let final = [];
	for (let item of data) {
		const cols = Object.keys(item).filter((column) =>
			available_columns.length ? available_columns.includes(column) : true
		);
		if (cols.length > final.length) {
			final = cols;
		}
	}

	return final;
};

const generateSheet = (columns, data, template, name) => {
	data = data.map((item) => {
		return columns.reduce((result, column) => {
			const value = item[column];

			result.push(value);

			return result;
		}, []);
	});
	const final = [
		columns.map((col) => get(template, col + ".name", col)),
		...data,
	];

	const worksheet = XLSX.utils.aoa_to_sheet(final);

	var wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, worksheet, name);

	XLSX.writeFile(wb, name + ".xlsx");
};
export default {
	groupBy,
	getColumns,
	generateSheet,
};
