import * as pd from "./table";

export const create_trading_desks_options = (trading_desks) => {
	if (!trading_desks) return [];
	return pd.table_to_object(
		trading_desks,
		["trading_desk_id", "rps_name"],
		["key", "text"]
	);
};

export const create_books_options = (books) => {
	if (!books) return [];
	return books.map((x) => ({ key: x, text: x }));
};
export const create_sheet_name = (
	trading_desks,
	trading_desk_id,
	report_date,
	text = " "
) => {
	const trading_desk = pd
		.table_to_object(trading_desks)
		.find(
			(trading_desk) => trading_desk.trading_desk_id === trading_desk_id
		);
	if (!trading_desk) return;

	return (
		trading_desk.rps_name +
		text +
		report_date.toLocaleDateString().replace(/\//g, "-")
	);
};

export const create_report_types_options = (is_final) => {
	if (!is_final)
		return [
			{
				key: 0,
				text: "Posição",
			},
			{
				key: 1,
				text: "Solicitado",
			},
			{
				key: 2,
				text: "Executado",
			},
		];

	return [
		{
			key: 0,
			text: "Oficial",
		},
	];
};
