import React from "react";
import { chartTypes, chartHeights } from "../../services/ApexChartsUtils";
import GeneralDisplay from "../../components/GeneralDisplay";
import GeneralDisplayFabric from "../../components/GeneralDisplayFabric";

import Chart from "react-apexcharts";
import { Text } from "office-ui-fabric-react";
import template from "../../services/tableTemplate";
import stackConstants from "./stackConstants";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import Logo from "../../media/logo.png";

function renderValue(cell) {
	switch (cell.key) {
		case "exposition_percent":
		case "performance":
		case "change":
		case "change_spx":
		case "profit":
		case "confidence":
		case "year":
		case "month":
		case "day":
		case "month92":
			return cell.value !== undefined
				? (cell.value * 100).toFixed(2) + "%"
				: undefined;
		case "date":
			return cell.value.split("-").reverse().join("/");
		default:
			return cell.value;
	}
}

function Sheet({ data, style }) {
	const [head, rows] = React.useMemo(() => {
		if (data.length <= 1) return [[], []];

		const copy = Array.from(data);
		return [copy.shift(), copy];
	}, [data]);

	if (!head) return <></>;

	return (
		<table
			border="1"
			style={{
				borderCollapse: "collapse",
				border: "1px solid rgb(220,220,220)",
				...style,
			}}
		>
			<thead>
				<tr>
					{head.map((x, i) => {
						return (
							<th
								style={{
									fontFamily: "Calibri",
									textAlign: x.align || "right",
									padding: "4px 2px 0 4px",
								}}
							>
								{renderValue(x)}
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{rows.map((row) => {
					return (
						<tr>
							{row.map((x, i) => {
								return (
									<td
										style={{
											fontFamily: "Calibri",
											textAlign:
												x.align || x.value === undefined
													? "center"
													: !isNaN(x.value)
													? "right"
													: "left",
											padding: "4px 2px 0 4px",
										}}
									>
										{renderValue(x)}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

export default ({
	info,
	profitChart,
	profit5dChart,
	profit21dChart,
	longShortChart,
	histogramChart,
	topLongChangeChart,
	topShortChangeChart,
	ibov,
	performanceRanking,
	docInfo,
	canvasCompat,
	bookSelection,
	bookList,
	vols,
	subbookVols,
}) => {
	const bookGroups = React.useMemo(() => {
		return bookList.reduce((data, item) => {
			const selected = bookSelection.length
				? bookSelection.includes(item)
				: true;
			let book = item.split(" - ");
			const parent = book[0].trim();
			book.shift();
			book = book.join(" - ");
			if (!data[parent]) {
				data[parent] = {
					group: parent,
					selected: selected ? 1 : 0,
					books: [{ book, selected }],
				};
			} else {
				if (selected) data[parent].selected += 1;

				data[parent].books.push({ book, selected });
			}
			return data;
		}, {});
	}, [bookList, bookSelection]);
	return (
		<div id="pdf" style={{ position: "relative" }}>
			{canvasCompat && (
				<>
					<div
						style={{
							position: "absolute",
							top: 8,
							width: "100%",
							display: "flex",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						<img src={Logo} width={127} height={36} />
					</div>
					<br />
					<br />
					<div style={{ display: "flex", padding: 16 }}>
						<GeneralDisplay info={{ ...docInfo }} separation={1} />
					</div>

					{bookList.length && (
						<div
							style={{
								display: "flex",
								padding: 16,
								justifyContent: "space-between",
							}}
						>
							<div style={{ display: "flex" }}>
								{Object.values(bookGroups)
									.sort((a, b) =>
										a.selected < b.selected ? 1 : -1
									)
									.map(({ group, selected, books }) => {
										return (
											<>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														marginRight: 8,
													}}
												>
													<span
														style={{
															fontFamily: "Arial",
															fontWeight: "bold",
															fontSize: 12,
															color: selected
																? undefined
																: "rgb(210,210,210)",
															marginBottom: 8,
														}}
													>
														{group}
													</span>

													{books
														.sort((a, b) =>
															a.selected >
															b.selected
																? -1
																: 1
														)
														.map(
															(
																{
																	book,
																	selected,
																},
																i
															) => (
																<span
																	style={{
																		fontFamily:
																			"Arial",
																		fontSize: 11,
																		color: selected
																			? undefined
																			: "rgb(210,210,210)",
																	}}
																>
																	{book}
																</span>
															)
														)}
												</div>
											</>
										);
									})}
							</div>
						</div>
					)}
				</>
			)}

			<div style={{ display: "flex", padding: 16 }}>
				<GeneralDisplay
					template={template}
					info={info}
					separation={1}
				/>
			</div>

			<div
				style={{
					display: "flex",
					padding: "0 16px 16px 16px",
				}}
			>
				<Sheet data={vols} style={{ flex: 1 }} />
			</div>
			<div
				style={{
					display: "flex",
					padding: "0 16px 16px 16px",
				}}
			>
				<Sheet data={subbookVols} style={{ flex: 1 }} />
			</div>

			{profitChart && (
				<>
					<Chart
						height={chartHeights.MEDIUM}
						options={profitChart.options}
						series={profitChart.series}
					/>
				</>
			)}
			{profit5dChart && (
				<>
					<Chart
						height={chartHeights.MEDIUM}
						options={profit5dChart.options}
						series={profit5dChart.series}
					/>
				</>
			)}
			{profit21dChart && (
				<>
					<Chart
						height={chartHeights.MEDIUM}
						options={profit21dChart.options}
						series={profit21dChart.series}
					/>
				</>
			)}

			{longShortChart && (
				<>
					<Chart
						height={chartHeights.BIG}
						options={longShortChart.options}
						series={longShortChart.series}
					/>
				</>
			)}

			{histogramChart && (
				<>
					<Chart
						height={chartHeights.MEDIUM}
						type={chartTypes.BAR}
						plotOptions={histogramChart.plotOptions}
						options={histogramChart.options}
						series={histogramChart.series}
					/>
				</>
			)}

			{topLongChangeChart && (
				<>
					<Chart
						style={stackConstants.flexOne}
						height={chartHeights.SMALL}
						type={chartTypes.BAR}
						options={topLongChangeChart.options}
						series={topLongChangeChart.series}
					/>
				</>
			)}

			{topShortChangeChart && (
				<>
					<Chart
						style={stackConstants.flexOne}
						height={chartHeights.SMALL}
						type={chartTypes.BAR}
						options={topShortChangeChart.options}
						series={topShortChangeChart.series}
					/>
				</>
			)}

			<div style={{ display: "flex", padding: 16 }}>
				{ibov.positive.length > 1 && (
					<>
						<div>
							<span
								style={{
									fontFamily: "Arial",
									fontWeight: "bold",
									fontSize: 16,
									lineHeight: 2,
								}}
							>
								Maiores Altas da Cota
							</span>
							<br />
							<Sheet data={ibov.positive} />
						</div>
						<div style={{ width: 16 }} />
					</>
				)}
				{ibov.negative.length > 1 && (
					<>
						<div>
							<span
								style={{
									fontFamily: "Arial",
									fontWeight: "bold",
									fontSize: 16,
									lineHeight: 2,
								}}
							>
								Maiores Quedas da Cota
							</span>
							<br />
							<Sheet data={ibov.negative} />
						</div>
						<div style={{ width: 16 }} />
					</>
				)}
				{performanceRanking.best.length > 1 && (
					<>
						<div>
							<span
								style={{
									fontFamily: "Arial",
									fontWeight: "bold",
									fontSize: 16,
									lineHeight: 2,
								}}
							>
								Melhor Performance
							</span>
							<br />
							<Sheet
								title={"Melhor Performance"}
								data={performanceRanking.best}
							/>
						</div>
						<div style={{ width: 16 }} />
					</>
				)}
				{performanceRanking.worst.length > 1 && (
					<div>
						<span
							style={{
								fontFamily: "Arial",
								fontWeight: "bold",
								fontSize: 16,
								lineHeight: 2,
							}}
						>
							Pior Performance
						</span>
						<br />
						<Sheet data={performanceRanking.worst} />
					</div>
				)}
			</div>
		</div>
	);
};
