import React, { useState, useEffect, useContext, useMemo } from "react";
import PanStack from "../../components/PanStack";
import DayPickerStrings from "../../services/DayPicketStrings";

import * as utils from "../../services/reportRiskUtils";
import Table from "../../components/Table";
import template from "../../services/tableTemplateRisk";
import ArrayMath, { sum } from "../../services/ArrayMath";
import GeneralDisplay from "../../components/GeneralDisplay";
import calcs from "../../services/calcs";
import stackConstants from "./stackConstants";
import {
	Dropdown,
	ActionButton,
	DatePicker,
	Label,
	Stack,
	Text,
	Spinner,
	SpinnerSize,
	Icon,
	Separator,
	DropdownMenuItemType,
} from "office-ui-fabric-react";
import {
	chartType1,
	chartTitleType1,
	chartType2,
	formatISODate,
} from "../../services/ApexChartsUtils";
import ReportWidget from "./ReportWidget";
import A4 from "./A4";
import Logo from "../../components/Logo";
import { mergeBooksExposition } from "../../services/mergeBooks";
import { topercent } from "../../services/templateUtils";
import { usePortfolioState } from "../../PortfolioContext";
import { useRiskReportingStore } from "../../RiskContext";
import { create_trading_desks_options } from "../../lib/utils";
import { fetch_risk_report } from "../../lib/api";

const MDatePicker = ({
	label,
	minDate,
	maxDate,
	onChange,
	value,
	placeholder = "Selecione uma data",
	disabled,
}) => {
	function handleSelect(nextDate) {
		if (onChange) {
			onChange(nextDate);
		}
	}
	return (
		<div>
			<Label>{label}</Label>
			<Stack verticalAlign="center" horizontal tokens={{ maxWidth: 170 }}>
				<DatePicker
					formatDate={(d) => d && d.toLocaleDateString()}
					onSelectDate={handleSelect}
					strings={DayPickerStrings}
					value={value}
					placeholder={placeholder}
					maxDate={maxDate}
					minDate={minDate}
					disabled={disabled}
				/>
				{value && (
					<ActionButton
						onClick={() => handleSelect(null)}
						styles={{
							root: { height: 0 },
							icon: { color: "black", fontSize: 12 },
						}}
						iconProps={{
							iconName: "Clear",
						}}
					/>
				)}
			</Stack>
		</div>
	);
};

function createVolTable(seriesData) {
	return Object.entries(seriesData).map(([book, series]) => {
		const volYear = ArrayMath.standardDeviation(series) * Math.sqrt(252);
		const volMonth = volYear / Math.sqrt(12);
		const volDay = volYear / Math.sqrt(252);

		return {
			book,
			year: volYear,
			month: volMonth,
			day: volDay,
			month92: volMonth * 1.41,
		};
	});
}
export default function RiskReporting() {
	const { trading_desks } = usePortfolioState();

	const trading_desks_options = useMemo(
		() => create_trading_desks_options(trading_desks),
		[trading_desks]
	);

	const {
		trading_desk_id,
		bookSelection,
		walletDate,
		patrimony,
		prices,
		report,
		dates,
		startDate,
		endDate,
		viewDate,
		setValue,
		loading,
		viewDocument,
		books,
		bookList,
	} = useRiskReportingStore();
	const generateSheetName = () => {
		const d = walletDate || new Date(Date.now());
		const { text } = trading_desks_options.find(
			({ key }) => key === trading_desk_id
		);

		return text + " " + d.toLocaleDateString().replace(/\//g, "-");
	};
	const table = React.useRef({});

	React.useEffect(() => {
		if (!dates.length) return;
		let date = dates[dates.length - 1];

		date = new Date(date);
		date.setHours(24);
		setValue("viewDate", date);
	}, [dates]);
	const [isoDate, dateIndex] = React.useMemo(() => {
		if (viewDate) {
			const iso = viewDate.toISOString().split("T")[0];
			return [iso, dates.indexOf(iso)];
		}
		return [undefined, undefined];
	}, [dates, viewDate]);

	const {
		profit,
		exposition_percent,
		profit_percent_series,
		profit_percent_books_series,
		profit_percent_subbooks_series,
		data,
		tickerData,
	} = React.useMemo(() => {
		const final = {
			exposition: 0,
			exposition_percent: 0,
			profit: 0,
			profit_percent: 0,
			profit_series: [],
			profit_percent_series: [],
			profit_percent_books_series: {},
			profit_percent_subbooks_series: {},
			data: [],
			tickerData: [],
		};

		let data = report
			.filter((x) => {
				if (!x.exposition) return;
				if (!bookSelection.length) return true;
				const book = [x.book, x.subbook].filter((x) => x).join(" - ");
				return bookSelection.includes(book);
			})
			.map((item) => {
				if (!final.profit_series.length) {
					final.profit_series = item.profit;
					final.profit_percent_series = item.profit_percent;
				} else {
					final.profit_series = final.profit_series.map(
						(x, i) => item.profit[i] + x
					);
					final.profit_percent_series =
						final.profit_percent_series.map(
							(x, i) => item.profit_percent[i] + x
						);
				}

				if (bookSelection.length > 1 || bookSelection.length == 0) {
					if (!final.profit_percent_books_series[item.book]) {
						final.profit_percent_books_series[item.book] =
							item.profit_percent;
					} else {
						final.profit_percent_books_series[item.book] =
							final.profit_percent_books_series[item.book].map(
								(x, i) => item.profit_percent[i] + x
							);
					}
				}

				if (bookSelection.length > 1 || bookSelection.length == 0) {
					const book = [item.book, item.subbook]
						.filter((x) => x)
						.join(" - ");
					if (item.book !== "Paolo") {
						if (!final.profit_percent_subbooks_series[book]) {
							final.profit_percent_subbooks_series[book] =
								item.profit_percent;
						} else {
							final.profit_percent_subbooks_series[book] =
								final.profit_percent_subbooks_series[book].map(
									(x, i) => item.profit_percent[i] + x
								);
						}
					}
				}

				final.exposition_percent += item.exposition_percent;

				const profit = item.profit[dateIndex] || 0;
				const profit_percent = item.profit_percent[dateIndex] || 0;
				let tickerPrices = [];
				let price = 0;
				let change = 0;
				let change_period = 0;
				for (const tickerData of prices) {
					if (tickerData.symbol == item.symbol) {
						tickerPrices = tickerData.prices;
						change_period = tickerData.change_period;

						price = tickerData.prices[dateIndex] || 0;
						change = tickerData.change[dateIndex] || 0;
						break;
					}
				}

				return {
					...item,
					prices: tickerPrices,
					price,
					change,
					change_period,
					profit,
					profit_percent,
				};
			});

		//final.profit_percent_subbooks_series = <final className="profit_per"></final>

		console.log(final.profit_percent_books_series);
		final.data = data.map((x) => ({ ...x, prices: undefined }));

		final.profit = isoDate ? final.profit_series[dateIndex] : 0;
		final.profit_percent = isoDate
			? final.profit_percent_series[dateIndex]
			: 0;

		if (!bookSelection.length)
			final.data = mergeBooksExposition(final.data);
		final.tickerData = mergeBooksExposition(data);
		return final;
	}, [report, viewDate, viewDocument, bookSelection]);

	const quotation = React.useMemo(
		() => ArrayMath.accumulate(profit_percent_series),
		[profit_percent_series]
	);

	const index = React.useMemo(() => {
		return (
			prices.find((x) => x.symbol === "IBX") || { prices: [], change: [] }
		);
	}, [prices]);

	const index_spx = React.useMemo(() => {
		return (
			prices.find((x) => x.symbol === "SPX") || { prices: [], change: [] }
		);
	}, [prices]);

	const finalReport = React.useMemo(() => {
		const indexChange = index.change;

		let tracking_error;
		if (trading_desk_id === 2)
			tracking_error = utils.trackingError(
				profit_percent_series,
				indexChange
			);

		const volDataBooks =
			Object.keys(profit_percent_books_series).length > 1
				? createVolTable(profit_percent_books_series)
				: [];

		const volData = [
			...(volDataBooks.length ? [...volDataBooks, {}] : []),
			...createVolTable({ Consolidado: profit_percent_series }),
		];

		const volDataSubbooks = createVolTable(
			profit_percent_subbooks_series
		).sort((a, b) => a.book.localeCompare(b.book));

		const volYear =
			ArrayMath.standardDeviation(profit_percent_series) * Math.sqrt(252);

		const vhead = [
			"Vol. Anual",
			"Vol. Mensal",
			"Vol. Diária",
			"VaR Mensal 92%",
		];
		const vkeys = ["year", "month", "day", "month92"];
		const vols = utils.dataToSheet(
			volData,
			[...(volDataBooks.length > 1 ? ["Book", "       "] : []), ...vhead],
			[...(volDataBooks.length > 1 ? ["book", ""] : []), ...vkeys],
			{ align: "center" }
		);

		const subbookVols = utils.dataToSheet(
			volDataSubbooks,
			["Subbook", ...vhead],
			["book", ...vkeys],
			{ align: "center" }
		);

		const info = {
			Dias: dates.length,
			volatility: volYear,
			mean: ArrayMath.mean(profit_percent_series),
			cumulative: quotation,
			ibx_perf: utils.assetPerformance(index.prices, 0),
			tracking_error,
			net: exposition_percent,
			sum_profit: profit || undefined,
		};

		const profitChart = chartType1(
			[
				{
					name: "P&L Simulado",
					data: profit_percent_series,
				},
				{
					name: "IBX",
					data: indexChange,
				},
			],
			dates,
			undefined,
			false,
			"P&L Simulado % (Diário)",
			!viewDocument
		);
		const profit21dSeries = utils.windowCumulativeReport(
			profit_percent_series
		);
		const ibov21dSeries = utils.windowCumulativeReport(indexChange);

		const profit21dChart = profit21dSeries
			? chartType1(
					[
						{
							name: "P&L Simulado",
							data: profit21dSeries,
						},
						{
							name: "IBX",
							data: ibov21dSeries,
						},
					],
					dates,
					undefined,
					false,
					"P&L Simulado % (21 Dias)",
					!viewDocument
			  )
			: null;

		const profit5dSeries = utils.windowCumulativeReport(
			profit_percent_series,
			5
		);
		const ibov5dSeries = utils.windowCumulativeReport(indexChange, 5);

		const profit5dChart = profit5dSeries
			? chartType1(
					[
						{
							name: "P&L Simulado",
							data: profit5dSeries,
						},
						{
							name: "IBX",
							data: ibov5dSeries,
						},
					],
					dates,
					undefined,
					false,
					"P&L Simulado % (5 Dias)",
					!viewDocument
			  )
			: null;

		const shortLongReport = utils.shortLongReport(report);
		const profitSeriesShort = utils.windowCumulativeReport(
			shortLongReport.profitSeriesShort
		);
		const profitSeriesLong = utils.windowCumulativeReport(
			shortLongReport.profitSeriesLong
		);
		const longShortChart =
			profitSeriesShort && shortLongReport
				? chartType1(
						[
							{
								name: "Long",
								data: profitSeriesLong,
							},
							{
								name: "Short",
								data: profitSeriesShort,
							},
						],
						dates,
						undefined,
						true,
						"P&L Simulado % - Long e Short (21 Dias)",
						!viewDocument
				  )
				: null;

		const histogramReport = ArrayMath.histogram(profit_percent_series);
		const histogramChart = histogramReport.data.length
			? {
					options: {
						chart: {
							id: "basic-bar",
							toolbar: {
								show: !viewDocument,
							},
						},
						title: chartTitleType1(
							"Histograma do P&L Simulado % (Diário)"
						),
						xaxis: {
							categories: histogramReport.intervals.map((x) => [
								`${(x[0] * 100).toFixed(1)}%`,
								`${(x[1] * 100).toFixed(1)}%`,
							]),
						},
						plotOptions: {
							bar: {
								columnWidth: "100%",
								barHeight: "100%",
							},
							dataLabels: {
								position: "top",
								maxItems: 100,
								hideOverflowingLabels: true,
							},
						},
					},

					series: [
						{
							name: "P&L Simulado",
							data: histogramReport.data,
						},
					],
			  }
			: null;
		const performance21d = utils.assetsPerformance(tickerData, 22);

		console.log(performance21d);
		const _performanceRanking = utils.ranking(
			performance21d,
			"exposition",
			25
		);
		console.log(_performanceRanking);

		const performanceRanking = {};
		const head = ["ATIVO", "EXP %", "PX % 21D"];
		const keys = ["symbol", "exposition_percent", "performance"];
		performanceRanking.best = utils.dataToSheet(
			_performanceRanking.best,
			head,
			keys
		);
		performanceRanking.worst = utils.dataToSheet(
			_performanceRanking.worst,
			head,
			keys
		);

		const top10report = utils.top10change(performance21d);

		let topLongChangeChart;
		let topShortChangeChart;

		topLongChangeChart = top10report.long.series.length
			? chartType2(
					[
						{
							name: "Variação %",
							data: top10report.long.series,
						},
					],
					top10report.long.categories,
					undefined,
					true,
					"Melhores % (21 dias)",
					!viewDocument
			  )
			: null;

		topShortChangeChart = top10report.long.series.length
			? chartType2(
					[
						{
							name: "Variação %",
							data: top10report.short.series,
						},
					],
					top10report.short.categories,
					undefined,
					true,
					"Piores % (21 dias)",
					!viewDocument
			  )
			: null;
		const ibov = utils.indexPerformance(
			index,
			index_spx,
			profit_percent_series,
			dates
		);

		return {
			info,
			profitChart,
			profit5dChart,
			profit21dChart,
			histogramChart,
			longShortChart,
			topLongChangeChart,
			topShortChangeChart,
			ibov,
			performanceRanking,
			bookSelection,
			bookList,
			vols,
			subbookVols,
		};
	}, [
		viewDate,
		viewDocument,
		report,
		patrimony,
		index,
		index_spx,
		dates,
		profit,
		quotation,
		profit_percent_series,
		bookSelection,
	]);

	const controls = useMemo(() => {
		async function getRiskReport() {
			setValue("viewDate", null);
			setValue("data", null);
			setValue("loading", true);

			try {
				const data = await fetch_risk_report(
					trading_desk_id,
					walletDate,
					startDate,
					endDate
				);
				if (!data) throw Error();
				setValue("data", data);
			} catch (error) {
				console.error(error);
			}
			setValue("loading", false);
		}
		async function generatePDF() {
			setValue("viewDocument", true);
		}

		return (
			<PanStack
				verticalAlign="end"
				horizontal
				tokens={{
					padding: 8,
					childrenGap: 8,
				}}
			>
				<Dropdown
					label="Fundo"
					selectedKey={trading_desk_id}
					onChange={(e, { key }) => setValue("trading_desk_id", key)}
					options={trading_desks_options}
					style={{ width: 120 }}
				/>

				<MDatePicker
					label="Carteira"
					value={walletDate}
					onChange={(date) => setValue("walletDate", date)}
					maxDate={new Date()}
					placeholder={"Mais Recente"}
				/>

				<MDatePicker
					label="Data Inicial"
					value={startDate}
					onChange={(date) => setValue("startDate", date)}
					maxDate={endDate}
				/>
				<MDatePicker
					label="Data Final"
					value={endDate}
					onChange={(date) => setValue("endDate", date)}
					minDate={startDate}
				/>
				<Dropdown
					label="Book"
					multiSelect
					options={books}
					placeholder={"Consolidado"}
					style={{ width: 180 }}
					styles={{ dropdownItemsWrapper: { maxHeight: 450 } }}
					onChange={(e, item) => setValue("bookSelection", item)}
					selectedKeys={bookSelection}
					disabled={!report.length}
					ariaLabel=""
				/>
				<MDatePicker
					label="Visualizar dia"
					disabled
					value={viewDate}
					onChange={(date) => setValue("viewDate", date)}
					minDate={startDate}
					maxDate={endDate}
					disabled={!report.length}
				/>
				<ActionButton
					disabled={loading}
					onClick={getRiskReport}
					iconProps={{
						iconName: "AnalyticsReport",
					}}
				>
					Gerar Relatório
				</ActionButton>
				{report.length && (
					<>
						<ActionButton
							disabled={loading}
							onClick={generatePDF}
							iconProps={{
								iconName: "PDF",
							}}
						>
							Gerar PDF
						</ActionButton>
						<ActionButton
							disabled={loading}
							onClick={() => {
								table.current.toExcel(
									table.current.data,
									generateSheetName()
								);
							}}
							iconProps={{
								iconName: "ExcelDocument",
							}}
						>
							Exportar Cateira
						</ActionButton>
					</>
				)}

				<Separator vertical />
			</PanStack>
		);
	}, [
		trading_desk_id,
		trading_desks_options,
		walletDate,
		startDate,
		endDate,
		viewDate,
		report,
		loading,
		setValue,
	]);

	const docInfo = useMemo(() => {
		if (!dates.length) return {};

		const start = formatISODate(dates[0]);
		const end = formatISODate(dates[dates.length - 1]);

		const { text } =
			trading_desks_options.find(({ key }) => key === trading_desk_id) ||
			{};

		const portf = text;

		const wallet = walletDate
			? walletDate.toLocaleDateString()
			: new Date().toLocaleDateString();

		const day = viewDate
			? { "Dia Visualizado": viewDate.toLocaleDateString() }
			: {};

		return {
			Fundo: portf,
			Carteira: wallet,
			Início: start,
			Fim: end,
			...day,
			"Data do Relatório": new Date().toLocaleDateString(),
		};
	}, [trading_desks_options, trading_desk_id, walletDate, viewDate, dates]);

	if (!report.length) {
		return (
			<>
				{controls}
				<div style={stackConstants.flexOne}>
					<Stack style={stackConstants.fullHeightCentered}>
						{loading ? (
							<Spinner size={SpinnerSize.large} />
						) : (
							<>
								<Icon iconName={"SearchIssue"} />
								<Text variant={"large"}>Nada a mostrar</Text>
							</>
						)}
					</Stack>
				</div>
			</>
		);
	}

	return (
		<>
			{controls}
			<Stack horizontal style={stackConstants.style1}>
				<Stack style={stackConstants.flexOne}>
					<Table
						ref={table}
						canModify={false}
						width={"unset"}
						template={template}
						group={
							bookSelection.length > 1
								? ["book", "subbook"]
								: undefined
						}
						rowKey={"symbol"}
						orderedColumns={[
							"symbol",
							"exposition",
							"exposition_percent",
							"price",
							"change",
							"change_period",
							"change21Day",
							"profit",
							"profit_percent",
						]}
						data={data}
						firstLevelOpen
					/>
				</Stack>
				{!viewDocument && (
					<Stack style={stackConstants.style2}>
						<ReportWidget {...finalReport} docInfo={docInfo} />
					</Stack>
				)}
			</Stack>
			{viewDocument && (
				<A4>
					<ReportWidget
						{...finalReport}
						docInfo={docInfo}
						canvasCompat
					/>
				</A4>
			)}
		</>
	);
}
