import { isMobile } from "react-device-detect";

import React from "react";
import logo_lg from "../../media/logo.png";
import logo_sm from "../../media/logo-sm.png";

import Container from "./styles";

const Logo = ({ small }) => {
	if (small === undefined) small = isMobile;

	const src = small ? logo_sm : logo_lg;

	return (
		<img
			style={{
				alignSelf: "center",
				objectFit: "cover",
				maxHeight: 32,
				paddingLeft: 8,
				paddingRight: 8,
			}}
			src={src}
		/>
	);
};

export default Logo;
