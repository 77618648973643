import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    justify-content: center;
    img {
        margin: 8px;
        width: ${({ isMobile }) => (isMobile && '26px') || '128px'};
        height: ${({ isMobile }) => (isMobile && '26px') || 'unset'};
    }
`
export default Container
