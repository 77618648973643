const groupByKey = (items, key, dict, alt) => {
	const grouped = items.reduce((result, item) => {
		let group = result.find((x) => x[key] === item[key]);

		if (item[key] === null) {
			result.push(item);
			return result;
		}

		if (!group) {
			group = Object.entries(item).reduce(
				(g, [k, v]) => {
					g[k] = (k === key && item[k]) || null;
					return g;
				},
				{ childrenRows: [] }
			);
			if (alt) group[alt] = group[key];
			result.push(group);
		}

		dict &&
			Object.keys(group).forEach((k) => {
				const i = dict[k];
				if (i) {
					if (i["onGroup"]) {
						group[k] = i["onGroup"](group[k], item[k], key);
					}
				}
			});

		group.childrenRows.push(item);

		return result;
	}, []);

	if (alt & (alt !== key)) {
		return grouped.map((g) => {
			delete g[key];
			return g;
		});
	}
	return grouped;
};

const groupBy = (items, keys, dict, alt) => {
	if (!keys.length) return items;

	const root = groupByKey(items, keys[0], dict, alt);

	for (const group of root) {
		if (!group.childrenRows) continue;
		if (!group.childrenRows.length) continue;

		group.childrenRows = groupBy(
			group.childrenRows,
			keys.slice(1),
			dict,
			alt
		);
	}

	return root;
};

const ungroup = (items) => {};
export default groupBy;
