import React, { useCallback, useContext, useMemo } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Stack, ActionButton } from "office-ui-fabric-react";
import stackConstants from "./stackConstants";

import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";
import { usePortfolioState } from "../../PortfolioContext";
import { useRiskReportingStore } from "../../RiskContext";
import { create_trading_desks_options } from "../../lib/utils";

const width = 900;
const height = 1272;
function resizeImage(canvas) {
	return new Promise((resolve) => {
		const src = canvas.toDataURL("image/png", 1.0);
		const img = document.createElement("img");

		const rc = document.createElement("canvas");
		rc.width = width;
		rc.height = parseInt((canvas.height * width) / canvas.width);

		const rctx = rc.getContext("2d");

		img.onload = () => {
			rctx.drawImage(img, 0, 0, rc.width, rc.height);
			const rsrc = rc.toDataURL("image/png", 1.0);
			resolve({ canvas: rc, image: rsrc });
		};
		img.src = src;
	});
}

export default ({ children }) => {
	const { trading_desks } = usePortfolioState();

	const trading_desks_options = useMemo(
		() => create_trading_desks_options(trading_desks),
		[trading_desks]
	);

	const { trading_desk_id, setValue } = useRiskReportingStore();

	const downloadDocument = useCallback(() => {
		const html = document.querySelector("#pdf").cloneNode(true);
		html.style = "max-width:900px";
		html.querySelector(".resize-triggers").remove();

		const { text } =
			trading_desks_options.find(({ key }) => key === trading_desk_id) ||
			{};

		const title = document.createElement("title");
		title.innerText = `RiskReport ${text} (${new Date()
			.toLocaleDateString()
			.split("/")
			.join("-")})`;

		html.appendChild(title);
		html.querySelectorAll("div").forEach((div) => {
			const divClass = div.getAttribute("class");
			if (divClass) {
				if (divClass.includes("tooltip")) {
					try {
						div.remove();
					} catch (err) {}
				}
			}
		});

		var x = window.open();
		x.document.open();
		x.document.write(html.innerHTML);
		x.document.close();
		x.print();
		setTimeout(() => {
			x.close();
		}, 100);
	}, [trading_desks_options, trading_desk_id]);

	const close = useCallback(() => {
		setValue("viewDocument", false);
	}, [setValue]);
	return (
		<div
			style={{
				zIndex: 1,
				position: "absolute",
				width: "100vw",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				background: "rgba(0,0,0,0.5)",
				overflow: "auto",
				alignItems: "center",
			}}
		>
			<Stack
				horizontal
				tokens={stackConstants.tokens1}
				style={{ marginTop: 16, background: "white" }}
			>
				<ActionButton
					onClick={downloadDocument}
					styles={{
						root: { height: 0 },
						icon: { color: "black", fontSize: 12 },
					}}
					iconProps={{
						iconName: "Download",
					}}
				>
					Salvar
				</ActionButton>
				<ActionButton
					onClick={close}
					styles={{
						root: { height: 0 },
						icon: { color: "black", fontSize: 12 },
					}}
					iconProps={{
						iconName: "ChromeClose",
					}}
				>
					Fechar
				</ActionButton>
			</Stack>

			<div
				style={{
					background: "white",
					boxShadow: Depths.depth16,
					marginBottom: 16,
				}}
			>
				<div
					style={{
						width,
						maxWidth: width,
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};
