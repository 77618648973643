export default (assets) => {
    let merged = {};

    for (let asset of assets) {
        const { symbol, amount } = asset;

        const existing = merged[symbol];

        if (existing) {
            existing.amount += amount;
        } else {
            asset = { ...asset };
            asset.book = undefined;
            asset.subbook = undefined;
            merged[symbol] = asset;
        }
    }
    return Object.values(merged);
};

export const mergeBooksExposition = (assets) => {
    let merged = {};

    for (let asset of assets) {
        const { symbol } = asset;

        const existing = merged[symbol];

        if (existing) {
            existing.exposition += asset.exposition;
            existing.exposition_percent += asset.exposition_percent;
            existing.profit += asset.profit;
            existing.profit_percent += asset.profit_percent;
        } else {
            asset = { ...asset };
            asset.book = undefined;
            asset.subbook = undefined;
            merged[symbol] = asset;
        }
    }
    return Object.values(merged);
};
