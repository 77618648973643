export const twodecimals = (val) => val.toFixed(2);
export const topercent = (val) => `${twodecimals(val * 100)} %`;
export const tobps = (val, decimals = 2) =>
	`${(val * 10000).toFixed(decimals)}bps`;

export const tocurrency = (val) =>
	val ? val.toFixed(2).replace(".", ",") : undefined;

export const humanize = (val, item, division = 1) =>
	new Intl.NumberFormat("pt-BR").format(Math.floor(val / division));

export const humanizek = (val) => humanize(val, null, 1000) + "k";

export const sum = (prev, val) => prev + val;
export const first = (prev, val) => val;

export const profitColor = (val) => (val > 0 && "limegreen") || "#EA2027";
