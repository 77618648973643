import React, { useMemo, useRef } from "react";
import { usePortfolioActions, usePortfolioState } from "../../PortfolioContext";
import * as pd from "../../lib/table";
import { create_report_types_options } from "../../lib/utils";
import { stack } from "../../styles";
import PanStack from "../../components/PanStack";
import { Dropdown, Label, Stack } from "office-ui-fabric-react";
import dropdownHandler from "../../lib/dropdownHandler";
import DatePicker from "../../components/DatePicker";
import template from "../../services/tableTemplate";
import Table from "../../components/Table";
import { columns } from "../../lib/defaults";
import indicatorsTemplate from "../../services/indicatorsTemplate";

const styles = {
	dropdown: { width: 150 },
};

function extract_indicators_table(products, prices, prices_periods) {
	const indicators = ["IBOVESPA", "IBX100", "NDX100", "SPX", "USDBRL"];
	products = pd.filter(products, ["description"], indicators, "keep");

	let view = pd.join_tables(products, prices, "product_id");
	view = pd.join_tables(view, prices_periods, "product_id");

	return pd.table_to_object(view);
}

export default function Resume() {
	let { report_date, report_type, loading, data } = usePortfolioState();

	const { set_report_date, set_report_type } = usePortfolioActions();

	const { resume, indicators } = useMemo(() => {
		let resume = data?.resume;
		if (loading || !resume?.data?.length)
			return { resume: [], indicators: [] };

		const indicators = extract_indicators_table(
			data.products,
			data.prices,
			data.prices_periods
		);

		resume = pd.join_tables(resume, data.trading_desks, "trading_desk_id");
		resume = pd.table_to_object(resume);

		return { resume, indicators };
	}, [loading, data, report_type]);

	const report_types_options = create_report_types_options(data?.is_final);

	return (
		<>
			<PanStack {...stack}>
				<Dropdown
					selectedKey={report_type}
					onChange={dropdownHandler(set_report_type)}
					placeholder=""
					label="Relatório"
					options={report_types_options}
					styles={styles}
					disabled={loading}
					styles={{ root: { width: 110 } }}
				/>
				<DatePicker
					label={"Data"}
					value={report_date}
					onChange={set_report_date}
					styles={{ root: { width: 130 } }}
					disabled={loading}
				/>
			</PanStack>
			<Stack>
				<Table
					rowKey="description"
					template={indicatorsTemplate}
					data={indicators}
					orderedColumns={columns.indicators}
					loading={loading}
				/>
			</Stack>
			<Table
				rowKey="ticker"
				template={template}
				data={resume}
				orderedColumns={columns.resume}
				loading={loading}
			/>
		</>
	);
}
