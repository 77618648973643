import React from "react";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { withRouter } from "react-router-dom";
import PanStack from "../PanStack";
import { stack } from "../../styles";
import Logo from "../Logo";

import "./index.css";
import { ActionButton, IconButton } from "office-ui-fabric-react";
import { useUserActions } from "../../UserContext";

const Tabs = ({ routes, history }) => {
	const { sign_out } = useUserActions();

	const handleClick = ({ props }) => history.push(props.itemKey);
	return (
		<PanStack>
			<div className={"tabs-container"}>
				<Logo />
				<Pivot
					selectedKey={history.location.pathname}
					onLinkClick={handleClick}
				>
					{routes
						.filter(({ name }) => name)
						.map(({ name, path }) => (
							<PivotItem
								key={path}
								itemKey={path}
								headerText={name}
							/>
						))}
				</Pivot>
				<div style={{ flex: 1 }}></div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: 44,
						paddingRight: 16,
					}}
				>
					<IconButton
						onClick={sign_out}
						iconProps={{ iconName: "SignOut" }}
					/>
				</div>
			</div>
		</PanStack>
	);
};

export default withRouter(Tabs);
