import styled, { createGlobalStyle } from "styled-components";
import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";

export const Paper = styled.div`
	position: relative;
	box-shadow: ${Depths.depth8};
	margin: 8px;
	flex: ${({ full }) => (full && 1) || "unset"};
	overflow: auto;
`;

export const stack = {
	verticalAlign: "center",
	horizontal: true,
	tokens: {
		padding: 8,
		childrenGap: 8,
	},
};
