import toISOString from "./toISOString";

function is_authenticated() {
	const authorization = localStorage.getItem("JLYWeskAlG");
	if (!authorization) throw new Error("Não autorizado");

	return { Authorization: "Bearer " + authorization };
}
export async function sign_in(user_name, password) {
	const body = JSON.stringify({ user_name, password });
	const response = await fetch("/v1/sign_in", {
		method: "POST",
		body,
		headers: {
			"Content-Type": "application/json",
		},
	});

	const { token, message } = await response.json();
	if (response.status >= 400) throw new Error(message);

	return token;
}

export async function sign_up(name, user_name, password, password_check) {
	const body = JSON.stringify({ name, user_name, password, password_check });
	const response = await fetch("/v1/sign_up", {
		method: "POST",
		body,
		headers: {
			"Content-Type": "application/json",
		},
	});

	const { message } = await response.json();
	if (response.status >= 400) throw new Error(message);

	return message;
}

export async function fetch_report(
	report_date,
	trading_desk_id,
	report_type,
	updated_at
) {
	const authorization_headers = is_authenticated();
	report_date = toISOString(report_date);
	updated_at = updated_at || 0;

	const params = new URLSearchParams({
		report_date,
		trading_desk_id,
		report_type,
		updated_at,
	}).toString();

	const response = await fetch(`/v1/overview?${params}`, {
		headers: authorization_headers,
		method: "POST",
	});

	if (response.status < 400) {
		const data = await response.json();
		if (data.updated_at !== updated_at) {
			return data;
		}
	}
}

export async function fetch_risk_report(
	trading_desk_id,
	report_date,
	start_date,
	end_date
) {
	const authorization_headers = is_authenticated();

	start_date = toISOString(start_date);
	end_date = toISOString(end_date);
	report_date = report_date ? toISOString(report_date) : undefined;

	let params = new URLSearchParams({
		trading_desk_id,
		start_date,
		end_date,
		...(report_date ? { report_date } : {}),
	});

	params = params.toString();

	const response = await fetch(`/v1/risk_report?${params}`, {
		headers: authorization_headers,
		method: "POST",
	});

	if (response.status >= 400) return;

	return await response.json();
}
