import React, { Fragment } from "react";
import {
    Stack,
    StackItem,
    Label,
    Text,
    Separator,
    Shimmer,
} from "office-ui-fabric-react";

const textStyles = (color, weight) => ({
    fontFamily: "Arial",
    fontSize: 20,
    color: color || "unset",
    fontWeight: weight || "unset",
});

const GeneralDisplay = ({ info, template, children, loading, separation }) => {
    template = template || {};
    const checkProp = (key, prop = null, target = null) => {
        const item = target ? target[key] : template[key];
        if (!prop) return item;
        if (!item) return false;
        return !!item[prop];
    };
    const getName = (key) =>
        (checkProp(key, "name") && template[key].name) || key;
    const getWeight = (key) => checkProp(key, "weight") && template[key].weight;

    const getColor = (key, val) =>
        (val && checkProp(key, "color") && template[key].color(val)) || "unset";

    const getValue = (key, val) =>
        (val && checkProp(key, "value") && template[key].value(val)) || val;

    const entries = Object.entries(info);
    return (
        <>
            {entries
                .filter(([k, v]) => v !== undefined)
                .map(([k, v], i) => (
                    <Fragment key={k}>
                        <div>
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontWeight: "bold",
                                    fontSize: 14,
                                }}
                            >
                                {getName(k)}
                            </span>
                            <div style={{ height: "8px" }} />
                            <span
                                style={textStyles(getColor(k, v), getWeight(k))}
                            >
                                {(v !== null && String(getValue(k, v))) || v}
                            </span>
                        </div>

                        <div
                            style={{
                                width: 1,
                                margin: "0 16px",
                            }}
                        />
                    </Fragment>
                ))}
        </>
    );
};
export default GeneralDisplay;
