import React, {
	useState,
	useImperativeHandle,
	forwardRef,
	useEffect,
	useCallback,
	useMemo,
} from "react";

import { Container } from "./styles";
import Row from "./Row";
import Cells from "./Cell";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import ToolBar from "./Toolbar";
import utils from "./utils";
import { get, call } from "safe-properties";
import { useDebounce } from "@react-hook/debounce";
import groupBy from "../../services/groupBy";

const { Cell, Column } = Cells;
const { getColumns, generateSheet } = utils;

const Table = (
	{
		width,
		group,
		rowKey,
		cantRemove,
		data,
		orderedColumns,
		onColumnsChanged,
		available_columns,
		template,
		onEdit,
		loading,
		canModify,
		firstLevelOpen,
	},
	ref
) => {
	const [columnsOrder, setColumnsOrder] = useState(orderedColumns);
	const [sort, setSort] = useState({ key: rowKey, direction: 1 });

	const [changedColumns, setChangedColumns] = useState([]);

	useEffect(() => {
		if (changedColumns.length > 0 && onColumnsChanged)
			onColumnsChanged(changedColumns);
	}, [changedColumns]);

	useEffect(() => {
		if (orderedColumns !== changedColumns) setColumnsOrder(orderedColumns);
	}, [orderedColumns]);

	const addColumn = useCallback(
		(key, to) => {
			let newOrder = [...columnsOrder];
			newOrder.splice(to, 0, key);

			setChangedColumns(newOrder);
			setColumnsOrder(newOrder);
		},
		[columnsOrder]
	);
	const removeColumn = useCallback(
		(key) => {
			let newOrder = columnsOrder.filter((x) => x !== key);

			setChangedColumns(newOrder);
			setColumnsOrder(newOrder);
		},
		[columnsOrder]
	);

	const moveColumn = useCallback(
		(dragIndex, hoverIndex) => {
			const toMove = columnsOrder[dragIndex];

			let newOrder = [...columnsOrder].filter(
				(item, i) => i !== dragIndex
			);
			newOrder.splice(hoverIndex, 0, toMove);

			setChangedColumns(newOrder);
			setColumnsOrder(newOrder);
		},
		[columnsOrder]
	);
	const onSort = useCallback(
		(key) => {
			if (!get(template, key + ".sort")) return null;

			let { key: prevKey, direction } = sort;
			return () =>
				setSort({
					key,
					direction: key !== prevKey ? 1 : direction * -1,
				});
		},
		[template, sort]
	);

	data = useMemo(() => {
		if (group)
			if (group.length)
				if (group[0]) return groupBy(data, group, template, rowKey);

		return data;
	}, [data, group, template, rowKey]);

	data = useMemo(() => {
		return data.sort((a, b) => {
			const { key, direction } = sort;
			const valA = a[key];
			const valB = b[key];

			if (valA < valB) return -1 * direction;
			if (valA > valB) return 1 * direction;
			return 0;
		});
	}, [data, sort]);

	cantRemove = cantRemove || [rowKey];
	available_columns = available_columns || [];
	let allColumns = data.length > 0 ? getColumns(data, available_columns) : [];
	let columns = [...allColumns];
	if (columnsOrder) columns = columnsOrder.filter((c) => columns.includes(c));

	let hiddenColumns = allColumns.filter((c) => !columns.includes(c));

	rowKey = rowKey || columns[0];

	const renderCell = useCallback(
		(item, key, rowKey) => {
			const val = item[key];
			return (
				<Cell
					rowKey={item[rowKey]}
					colKey={key}
					key={item[rowKey] + key}
					color={
						(val && call(template, key + ".color", val, item)) ||
						"white"
					}
					onEdit={get(onEdit, key)}
					value={val}
					display={
						(val && call(template, key + ".value", val, item)) ||
						val
					}
					textAlign={get(template, key + ".textAlign")}
					fontWeight={get(template, key + ".fontWeight")}
					maxWidth={get(template, key + ".maxWidth")}
				/>
			);
		},
		[onEdit, template]
	);

	const renderRow = useCallback(
		(item, i, level = 0) => (
			<Row
				key={`${item[rowKey]}'r'${i}`}
				columns={columns.map((key) => renderCell(item, key, rowKey))}
				group={group}
				level={level}
				firstLevelOpen={firstLevelOpen}
			>
				{item.childrenRows &&
					item.childrenRows.map((child, i) =>
						renderRow(child, i, level + 1)
					)}
			</Row>
		),
		[columns, renderCell, rowKey]
	);

	useImperativeHandle(
		ref,
		() => ({
			toExcel: (x, name, columnsOverride) => {
				generateSheet(columnsOverride || columns, x, template, name);
			},
			data,
		}),
		[data]
	);

	if (loading)
		return (
			<div>
				<Spinner size={SpinnerSize.large} />
			</div>
		);

	return (
		<>
			{canModify && (
				<ToolBar
					cantRemove={cantRemove}
					addColumn={addColumn}
					hiddenColumns={hiddenColumns.map((key) => ({
						key,
						display: template[key]?.name, //get(template, key + ".name", key),
					}))}
				/>
			)}
			<Container width={width}>
				<table>
					<thead>
						<tr>
							{columns.map((col) => (
								<Column
									removeColumn={removeColumn}
									sort={onSort(col)}
									color="black"
									value={col}
									key={col}
									canRemove={!cantRemove.includes(col)}
									index={columnsOrder.indexOf(col)}
									moveColumn={moveColumn}
									display={template[col]?.name} //get(template, col + ".name", col)}
									textAlign={template[col]?.textAlign} //get(template, col + ".textAlign")}
									maxWidth={template[col]?.maxWidth} //get(template, col + ".maxWidth")}
								/>
							))}
							{width === "unset" && <td className="fill" />}
						</tr>
					</thead>
					<tbody>{data.map((item, i) => renderRow(item, i))}</tbody>
				</table>
			</Container>
		</>
	);
};

export default forwardRef(Table);
