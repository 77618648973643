import { isMobile } from "react-device-detect";

import {
	humanize,
	humanizek,
	profitColor,
	sum,
	first,
	tocurrency,
	topercent,
	twodecimals,
	tobps,
} from "./templateUtils";

function sum_ticker(x, y, key) {
	if (key !== "basic_report_ticker") return;
	return (x || 0) + (y || 0);
}

function first_ticker(x, y, key) {
	if (key !== "basic_report_ticker") return;
	return y;
}
function first_ticker_rps_setor(x, y, key) {
	if (key === "rps_setor") return y;
	if (!["basic_report_ticker", "object_ticker"].includes(key)) return;
	return y;
}

function first_ticker_rps_subsetor(x, y, key) {
	if (key === "rps_subsetor") return y;
	if (!["basic_report_ticker", "object_ticker"].includes(key)) return;
	return y;
}

function first_object_ticker(x, y, key) {
	if (!["basic_report_ticker", "object_ticker"].includes(key)) return;
	return y;
}

function sum_gross_trading_desk(x, y, key) {
	if (["book_1", "book_2"].includes(key)) return;
	return x + y;
}

function sum_gross_book_1(x, y, key) {
	if (key !== "book_1") return;
	return x + y;
}

function sum_gross_book_2(x, y, key) {
	if (key !== "book_2") return;
	return x + y;
}

const textAlign = "right";

const dict = {
	region: {
		name: "Região",
	},
	theme: {
		name: "Tema",
	},
	liquidity: {
		name: "Liquidez",
	},
	book_1: {
		name: "Book",
		sort: true,
	},
	book_2: {
		name: "Sub Book",
		sort: true,
	},
	tipo: {
		name: "Tipo",
	},
	expiration: {
		name: "Vencimento",
		value: (value) => {
			const [y, m, d] = value.split("-");
			return `${d}/${m}/${y}`;
		},
	},
	net: {
		name: "Net %",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	volatility: {
		name: "Volatilidade",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	mean: {
		name: "Retorno Médio",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	cumulative: {
		name: "Retorno Acumulado",
		value: topercent,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	object_ticker: {
		name: "Objeto",
	},

	delete: { name: " " },
	ticker: {
		name: "Ativo",
		value: (x) => {
			if (isMobile && x.length > 15) return x.substring(0, 15) + "...";

			return x;
		},
		sort: true,
	},
	currency: {
		name: "Moeda",
		onGroup: first_ticker,
		sort: true,
	},
	rps_name: { name: "Fundo", sort: true },

	rps_setor: { name: "Tema", sort: true, onGroup: first_ticker_rps_setor },
	rps_subsetor: {
		name: "Setor",
		sort: true,
		onGroup: first_ticker_rps_subsetor,
	},
	trade_price: { name: "PX Trade", edit: true, textAlign },
	weight: {
		name: "Peso (IBX)",
		value: topercent,
		sort: true,
		textAlign,
		onGroup: first_ticker,
	},
	weight_d1: {
		name: "Peso (IBX) D-1",
		value: topercent,
		sort: true,
		textAlign,
		onGroup: first_ticker,
	},
	position_pnl_alpha_ibx_pct: {
		name: "Alpha",
		value: tobps,
		sort: true,
		textAlign,
		color: profitColor,
		weight: "bold",
		onGroup: sum,
	},
	sum_alpha: {
		name: "Alpha Total",
		value: tobps,
		sort: true,
		color: profitColor,
		weight: "bold",
		textAlign,
	},
	ow_uw_pct: {
		name: "Over/Under",
		value: topercent,
		sort: true,
		textAlign,
		onGroup: sum,
	},
	amount_d1: {
		name: "Quantidade D-1",
		value: humanize,
		sort: true,
		textAlign,
		onGroup: sum_ticker,
	},
	amount: {
		name: "Quantidade",
		value: humanize,
		sort: true,
		textAlign,
		onGroup: sum_ticker,
	},
	amount_adj: {
		name: "Quantidade Ajustada",
		value: humanize,
		sort: true,
		textAlign,
	},

	dtd_pct: {
		name: "PX %",
		value: topercent,
		sort: true,
		color: profitColor,
		onGroup: first_ticker,
		textAlign,
	},
	mtd_pct: {
		name: "PX MTD %",
		value: topercent,
		sort: true,
		color: profitColor,
		onGroup: first_ticker,
		textAlign,
	},
	ytd_pct: {
		name: "PX YTD %",
		value: topercent,
		sort: true,
		color: profitColor,
		onGroup: first_ticker,
		textAlign,
	},
	price_d1: {
		name: "PX D1",
		value: tocurrency,
		sort: true,
		textAlign,
		onGroup: first_ticker,
	},
	price_dx: {
		name: "PX 2021 (início)",
		value: tocurrency,
		sort: true,
		textAlign,
		onGroup: first_ticker,
	},
	price_d0: {
		name: "PX D0",
		value: tocurrency,
		sort: true,
		textAlign,
		onGroup: first_ticker,
	},
	price_d0_object_ticker: {
		name: "Derivativo",
		value: tocurrency,
		sort: true,
		textAlign,
		onGroup: first_ticker,
	},
	trade_amount: {
		name: "Quantidade",
		edit: true,
		textAlign,
	},
	trades_equity_exposure: {
		name: "Trades Exp. ",
		value: humanize,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	trades_equity_exposure_pct: {
		name: "Trades Exp. %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	equity_exposure: {
		name: "Exp.",
		value: humanize,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	fx_exposure_usdbrl_pct: {
		name: "USDBRL %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	equity_exposure_pct: {
		name: "Exp. %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	delta_notional_pct: {
		name: "Delta",
		value: topercent,
		sort: true,
		onGroup: first_ticker,
		textAlign,
	},
	position_d1: {
		name: "Posição D-1",
		value: humanize,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	position: {
		name: "Posição",
		value: humanize,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	position_pct: {
		name: (isMobile && "Pos. %") || "Posição %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	gross_pct_trading_desk: {
		name: (isMobile && "Gross % (Fundo)") || "Gross % (Fundo)",
		value: topercent,
		sort: true,
		onGroup: sum_gross_trading_desk,
		textAlign,
	},
	gross_pct_book_1: {
		name: (isMobile && "Gross % (Book)") || "Gross % (Book)",
		value: topercent,
		sort: true,
		onGroup: sum_gross_book_1,
		textAlign,
	},
	gross_pct_book_2: {
		name: (isMobile && "Gross % (Subbook)") || "Gross % (Subbook)",
		value: topercent,
		sort: true,
		onGroup: sum_gross_book_2,
		textAlign,
	},
	dv01_pct: {
		name: "DV01 (bps)",
		value: (x) => tobps(x, 1),
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	exp_adj: {
		name: "Exposição Ajustada",
		value: humanize,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	exp_adj_percent: {
		name: "Exposição Ajustada %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	exp_trade_percent: {
		name: "Exposição %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	position_pnl_pct: {
		name: "Pos. PnL %",
		value: topercent,
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	trades_pnl_pct: {
		name: "Trades PnL %",
		value: topercent,
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	pnl_pct: {
		name: "PnL %",
		value: topercent,
		onGroup: sum,
		color: profitColor,
		sort: true,
		textAlign,
	},
	pnl: {
		name: "PnL",
		value: humanize,
		sort: true,
		color: profitColor,
		onGroup: sum,
		textAlign,
		fontWeight: "bold",
	},
	sum_profit: {
		name: "PnL",
		value: humanizek,
		textAlign,
	},
	trade_profit: {
		name: "Lucro Trade",
		value: humanize,
		sort: true,
		color: profitColor,
		onGroup: sum,
		textAlign,
	},
	total_profit: {
		name: "Lucro Total",
		value: humanize,
		sort: true,
		color: profitColor,
		onGroup: sum,
		textAlign,
	},

	total_profit_percent: {
		name: "Lucro Total %",
		value: topercent,
		sort: true,
		onGroup: sum,
		textAlign,
	},
	profit_percent: {
		name: "Lucro Pos. %",
		value: topercent,
		onGroup: sum,
		sort: true,
		textAlign,
	},
	change21Day: {
		name: "PX % (21d)",
		value: topercent,
		onGroup: sum,
		sort: true,
		color: profitColor,
		textAlign,
	},
	ibx_perf: {
		name: "IBX",
		value: topercent,
		color: profitColor,
		weight: "bold",
	},
	tracking_error: {
		name: "Tracking Error",
		value: topercent,
	},
	cash: {
		name: "Caixa",
		value: topercent,
	},
};
export default dict;
