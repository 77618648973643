export default {
    months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],

    shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
    ],

    days: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
    ],

    shortDays: ["D", "S", "T", "Q", "Q", "S", "S"],

    goToToday: "Ir para Hoje",
    prevMonthAriaLabel: "Ir para mês anterior",
    nextMonthAriaLabel: "Ir para mês seguinte",
    prevYearAriaLabel: "Ir para o ano anterior",
    nextYearAriaLabel: "Ir para o ano seguinte",
    closeButtonAriaLabel: "Fechar",
};
