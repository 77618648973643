import styled, { css } from "styled-components";

export const Container = styled.td`
	max-width: ${(props) => props.maxWidth || "unset"};
	.text {
		color: ${({ color }) => color};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		${(props) =>
			props.fontWeight &&
			css`
				font-weight: ${props.fontWeight};
				font-size: 16px;
			`}
	}

	.container {
		display: flex;
		align-items: center;
		padding: 8px;
		padding-right: 16px;

		${(props) =>
			props.textAlign &&
			css`
				justify-content: ${props.textAlign === "right" && "flex-end"};
			`}
	}
`;
export const ColumnContainer = styled(Container)`
	cursor: grab;
	${(x) =>
		x.isDragging &&
		css`
			.container {
				border: dashed 1px grey;
				position: relative;
				margin: -1px;
			}
		`}

	${(x) =>
		x.side &&
		css`
			.container {
				border-${x.side === -1 ? "left" : "right"}: dashed 3px grey;
				position: relative;
				margin-${x.side === -1 ? "left" : "right"}: ${3}px;
			}
		`}
`;
